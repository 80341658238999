import React from 'react'
import greencouch from '../../../static/images/patio/green-couch-nav.jpeg'
import bed from '../../../static/images/bedroom/bed-nav.jpeg'
import bookshelf from '../../../static/images/living-room/bookshelf-nav.jpeg'
import balcony from '../../../static/images/patio/balcony-nav.jpeg'
import windowsill from '../../../static/images/window/sill-nav.jpeg'
import sink from '../../../static/images/kitchen/sink-nav.jpeg'
import backyard from '../../../static/images/patio/backyard-nav.jpeg'

export const IndoorLinkItems = {
  "sideLinks": [
    {
      "name": "Plants",
      "link": "/plants?indoor"
    },
    {
      "name": "Pots",
      "link": "/pots?indoor"
    }
  ],
   "mainLinks": [
    {
      "name": "Living Room",
      "link": "/indoor/living-room",
      "img": bookshelf
    },
    {
      "name": "Bedroom",
      "link": "/indoor/bedroom",
      "img": bed
    },
    {
      "name": "Kitchen",
      "link": "/indoor/kitchen",
      "img": sink
    }
  ]
}


export const OutdoorLinkItems = {
  "sideLinks": [
    {
      "name": "Plants",
      "link": "/plants?outdoor"
    },
    {
      "name": "Pots",
      "link": "/pots?outdoor"
    }
  ],
   "mainLinks": [
    {
      "name": "Balcony",
      "link": "/outdoor/balcony",
      "img": balcony
    },
    {
      "name": "Patio",
      "link": "/outdoor/patio",
      "img": backyard
    }
  ]
}

// ,
// {
//   "name": "Window",
//   "link": "/outdoor/window",
//   "img": windowsill
// }
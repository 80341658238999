import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ProductsContext } from './ProductsProvider'

export const CartContext = React.createContext()

/**
 * Manages the shopping cart, which is persisted in local storage.
 * The cart and related methods are shared through context.
 */

// localStorage.setItem('cart', '{}')
const CartProvider = ({ children }) => {
  const { skus } = useContext(ProductsContext)
  const [mode, setMode] = useState(false)
  const [isAdding, setAdding] = useState(false)
  const [coupon, setCoupon] = useState(null)
  const [invalidCoupon, setInvalidCoupon] = useState(false)
  const [lastItem, setLastItem] = useState({})

  /** Load cart from local storage. Initialize if not present or incorrect. */
  const [contents, setContents] = useState(() => {
    let localCart
    try {
      localCart = JSON.parse(localStorage.getItem('cart'))
    } catch (err) {
      console.error(err.message)
    }
    if (!localCart || !Array.isArray(localCart)) return []
    return localCart
  })

  /** Save cart to local storage after load and on update */
  useEffect(() => {
    try {
      localStorage.setItem('cart', JSON.stringify(contents))
    } catch (err) {
      console.error(err)
    }
  }, [contents])

  /** An array representing the cart in the form of [{sku}, quantity] */
  const cart = contents.map(([id, quantity]) => {
    return [skus[id], quantity]
  })

  /** The number of items in the cart */
  const itemsInCart = contents.reduce((sum, [_, quantity]) => sum + quantity, 0)

  /** The total cost of the items in the cart */
  const subTotalNoCoupon = contents.reduce(
    (sum, [id, quantity]) => sum + skus[id].price * quantity,
    0
  ) 
  const discount = coupon ? (subTotalNoCoupon * coupon) : 0
  const subTotal = subTotalNoCoupon - discount

  /** Tax rate 6% */
  const taxFee = subTotal * .06

  /** Delivery fee free over $50 */
  const deliveryFee = (subTotal < 5000) ? 500 : 0

  /** The total cost with fees */
  const total = subTotal + taxFee + deliveryFee

  /** validate a coupon */
  async function applyCoupon(name) {
    if (!name) {
      setInvalidCoupon(true)
      return
    }
    setInvalidCoupon(false)
    try {
      const response = await fetch('/.netlify/functions/coupon', {
        method: 'POST',
        body: JSON.stringify({
          name,
        })
      })
      if (response.status == 500) {
        setInvalidCoupon(true)
      } else {
        const { data } = await response.json() 
        const { percent_off } = data
        if (window) {
          window.analytics.track("Applied Coupon",{
            percent_off
          })
        }
        setCoupon(percent_off/100)
      }

    }
    catch (err) {
      console.log(err)
      alert(err.message)
    }
  }

  /** Sets quantity of item with `id` */
  function set(id, quantity) {
    if (!available(id)) return console.error('not available')
    const index = contents.findIndex(item => item[0] === id)
    setContents(state => {
      const newState = [...state]
      if (index !== -1) {
        newState[index] = [id, quantity]
      } else {
        newState.push([id, quantity])
      }
      return newState
    })
  }

  /** Increments item with `id` by `quantity`, which defaults to 0 */
  function add(id, quantity = 1) {
    if (window) {
      window.analytics.track("Add to Cart",{
        id,
        quantity
      })
    }
    setAdding(true)
    const currentItem = contents.find(item => item[0] === id)
    setLastItem(currentItem)
    const currentQuantity = currentItem ? currentItem[1] : 0
    let sum = Number(quantity) + Number(currentQuantity)
    set(id, sum)
    setTimeout(
      () => setAdding(false),
      1000
    )
  }

  /** Updates item with `id` by `quantity` */
  function update(id, quantity) {
    const currentItem = contents.find(item => item[0] === id)
    set(id, quantity)
  }


  /** Removes item with `id` */
  function remove(id) {
    setContents(state => {
      return state.filter(item => item[0] !== id)
    })
  }

  /** Returns true if `quantity` of item with `id` is available for purchase */
  function available(id, quantity = 1) {
    const sku = skus[id]
    if (!sku) {
      console.error(`Sku with id ${id} not found`)
      return false
    } else if (!sku.active) {
      console.error(`Sku with id ${id} is not active`)
      return false
    } else if (sku.inventory.type === 'infinite') {
      return true
    } else if (sku.inventory.type === 'bucket') {
      return ['in_stock', 'limited'].includes(sku.inventory.type)
    } else if (sku.inventory.type === 'finite') {
      return sku.inventory.quantity >= quantity
    } else {
      return false
    }
  }

  /** Toggles cart display, or sets to the boolean `force` if provided */
  function toggle(force) {

    /* Show / Hide Chat widget */
    if (mode) {
      zE('webWidget', 'show')
    } else {
      zE('webWidget', 'hide')
    }
    
    setMode(prev => force || !prev)
  }

  const ctx = {
    contents,
    lastItem,
    cart,
    add,
    set,
    remove,
    coupon,
    applyCoupon,
    invalidCoupon,
    setContents,
    available,
    update,
    toggle,
    itemsInCart,
    subTotal,
    taxFee,
    deliveryFee,
    total,
    isAdding,
    mode
  }

  return (
    <CartContext.Provider value={{ ...ctx }}>{children}</CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default CartProvider

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

export const ProductsContext = React.createContext()

/**
 * Wrapper to give Provider access to Sku nodes from Gatsby's GraphQL store.
 */
const ProductsProvider = ({ children }) => (
  <StaticQuery
    query={skusQuery}
    render={data => <Provider data={data}>{children}</Provider>}
  />
)

ProductsProvider.propTypes = {
  children: PropTypes.any.isRequired
}

/**
  Shares product information and availability through context.
  Products are first loaded from Gatsby's GraphQL store and then updated with
  current information from Stripe.
*/
const Provider = ({ data, children }) => {
  /** Load product data from Gatsby store */
  const [initialProducts, initialSkus] = processGatsbyData(data)
  const [products, setProducts] = useState(initialProducts)
  const [skus, setSkus] = useState(initialSkus)

  /** On render and update, update products with live data
  useEffect(() => {
    updateProducts()
  }, []) */

  /** Query live data from Stripe and update products */
  const updateProducts = async () => {
    const { data, error } = await fetch('/.netlify/functions/skuList')
      .then(response => response.json())
      .catch(error => console.error(error))

    if (error) {
      console.error(error)
    }

    const [liveProducts, liveSkus] = processStripeData(data, products)
    setProducts(liveProducts)
    setSkus(liveSkus)
  }

  function shuffle(arr) {
    for (var i = 0; i < arr.length - 1; i++) {
      var j = i + Math.floor(Math.random() * (arr.length - i));
      var temp = arr[j];
      arr[j] = arr[i];
      arr[i] = temp;
    }
    return arr
  }

  return (
    <ProductsContext.Provider
      value={{
        products,
        skus,
        listProducts: (type,sort,shuffle=false) => {
          let fn = sort || ((a, b) => b.created - a.created)
          const fl = ((a) => a.metadata.type==type) 
          if (shuffle) {
            fn = ((a, b) => 0.5 - Math.random())
          }

          return Object.values(products).filter(fl).sort(fn)
        },
        listSkus: (type, pot_bucket) => {
          const fl = ((a) => a.metadata.type==type)
          const fl2 = pot_bucket ? ((a) => a.metadata.pot_bucket==pot_bucket) : ((a) => a)  
          return Object.values(skus).filter(fl).filter(fl2)
        }
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}

Provider.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired
}

/** Normalize structure of data sourced from Gatsby's GraphQL store */
const processGatsbyData = data => {
  const initialProducts = {}
  const initialSkus = {}
  data.allStripeSku.group.forEach(group => {
    const sku = group.edges[0].node
    const product = { slug: sku.product.id, ...sku.product }
    product.skus = group.edges.map(({ node }) => {
      initialSkus[node.id] = node
      return node
    })
    if (product.skus.length > 1) {
      product.hasVariants = true
    }
    initialProducts[product.id] = product
  })
  return [initialProducts, initialSkus]
}

/** Normalize structure of live data sourced from Stripe */
const processStripeData = (data, products) => {
  const liveProducts = {}
  const liveSkus = {}
  data.forEach(source => {
    const { id } = source.product
    const target = products[id].skus.find(x => x.id === source.id)
    const updatedSku = Object.assign(source, target)
    if (!liveProducts[id]) {
      source.product.slug = products[id].slug
      liveProducts[id] = { ...source.product, skus: [] }
    }
    liveProducts[id].skus.push(updatedSku)
    liveSkus[updatedSku.id] = updatedSku
  })
  return [liveProducts, liveSkus]
}

export const skuFragment = graphql`
  fragment Sku on StripeSku {
    id
    price
    active
    metadata {
      pot_bucket
    }
    attributes {
      variants
      width
    }
    inventory {
      type
    }
    product {
      id
      name
      active
      created
      updated
      images
      metadata {
        type
        nick_name
      }
    }
  }
`

const skusQuery = graphql`
  query skusQuery {
    allStripeSku {
      group(field: product___id) {
        fieldValue
        edges {
          node {
            ...Sku
          }
        }
      }
    }
  }
`

export default ProductsProvider
import React, { useState } from 'react'
import Moment from 'moment'

import styles from './Time.module.scss'
import { FormInput, FormRow, FormCol } from 'components/shared/Forms'
import DatePicker from 'components/shared/DatePicker'
import { TIME } from './data'
import FormButton from 'components/Cart/elements/FormButton'

Moment.locale('en')
export default (props) => {
  const {
    initialData,
    onChange,
    onChangeDate,
    onClickHandler
  } = props
  const { d_date, d_time, d_instructions } = initialData
  const [focusedInput, setFocusedInput] = useState(false)
  

  /**
   * Determines if a particular day on the calendar is blocked
   * @param {Moment.moment} moment
   * @param {Array} availability
   */
  function isDayBlocked(day) {
    const unavailableDays = [Moment(),Moment().add(1, 'day'),Moment().add(2, 'day'),Moment().add(3, 'day')] 
    return unavailableDays.some((unavailableDay) => Moment(unavailableDay).isSame(day, 'day'));
  }

  return (
    <div>
      <div style={{padding:24,height:`100%`}}>
        <h5>Delivery Details</h5>
        <FormRow>
          <FormCol className={styles.dateColumn}>
          <FormInput label="Pick a date">
            <DatePicker
              date={d_date}
              id="d_date"
              displayFormat={() => "dddd, MMMM Do"}
              numberOfMonths={1}
              onDateChange={(date)=>onChangeDate(date)}
              focused={focusedInput}
              noBorder={true}
              isDayBlocked={moment => isDayBlocked(moment)}
              onFocusChange={({ focused }) =>setFocusedInput(focused)}
            />
            </FormInput>
          </FormCol>
          <FormCol>
            <FormInput label="Pick a time">
              <select
                name="d_time"
                required
                value={d_time}
                onChange={onChange}
              >
                <option value="" disabled="">
                  {' '}
                  Select time
                </option>
                {TIME.map(type => {
                  return (
                    <option value={type} key={type}>
                      {type}
                    </option>
                  )
                })}
              </select>
            </FormInput>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol>
            <FormInput label="Delivery Instructions">
              <textarea
                type="text"
                required
                name="d_instructions"
                id="d_instructions"
                maxLength="250"
                defaultValue={d_instructions}
                onChange={onChange}
              />
            </FormInput>
          </FormCol>
        </FormRow>
      </div>
      <FormButton {...props} nextReady={true} />
    </div>
  )
}

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-page-template-js": () => import("/opt/build/repo/src/templates/ProductPageTemplate.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indoor-bedroom-js": () => import("/opt/build/repo/src/pages/indoor/bedroom.js" /* webpackChunkName: "component---src-pages-indoor-bedroom-js" */),
  "component---src-pages-indoor-kitchen-js": () => import("/opt/build/repo/src/pages/indoor/kitchen.js" /* webpackChunkName: "component---src-pages-indoor-kitchen-js" */),
  "component---src-pages-indoor-living-room-js": () => import("/opt/build/repo/src/pages/indoor/living-room.js" /* webpackChunkName: "component---src-pages-indoor-living-room-js" */),
  "component---src-pages-legal-privacy-js": () => import("/opt/build/repo/src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("/opt/build/repo/src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-offices-js": () => import("/opt/build/repo/src/pages/offices.js" /* webpackChunkName: "component---src-pages-offices-js" */),
  "component---src-pages-outdoor-balcony-js": () => import("/opt/build/repo/src/pages/outdoor/balcony.js" /* webpackChunkName: "component---src-pages-outdoor-balcony-js" */),
  "component---src-pages-outdoor-patio-js": () => import("/opt/build/repo/src/pages/outdoor/patio.js" /* webpackChunkName: "component---src-pages-outdoor-patio-js" */),
  "component---src-pages-outdoor-window-js": () => import("/opt/build/repo/src/pages/outdoor/window.js" /* webpackChunkName: "component---src-pages-outdoor-window-js" */),
  "component---src-pages-plants-js": () => import("/opt/build/repo/src/pages/plants.js" /* webpackChunkName: "component---src-pages-plants-js" */),
  "component---src-pages-pots-js": () => import("/opt/build/repo/src/pages/pots.js" /* webpackChunkName: "component---src-pages-pots-js" */),
  "component---src-pages-thank-you-js": () => import("/opt/build/repo/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}


import React from 'react'
import styled from '@emotion/styled'

import { colors } from 'utils/styles'
import {
  MdClose,
  MdShoppingCart,
  MdChevronRight,
  MdArrowForward
} from 'react-icons/md'
import { FiShoppingCart, FiClipboard, FiTruck, FiPackage, FiClock, FiCreditCard } from 'react-icons/fi'

const DotWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin: 5px auto;
  justify-content: space-between;
  width: 250px;
`

const Dot = styled('div')`
  background-color: ${props =>
    props.selected ? colors.themeAccent : colors.gray300};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 10px;
`

const Step = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  svg {
    transition-duration:.2s;
    cursor: ${props =>
    props.clickable ? 'pointer' : 'auto'};
    color: ${props =>
    props.selected ? colors.gray900 : colors.gray400};
  }
`

const StepName = styled('div')`
  margin-top: 5px;
  color: ${colors.themeAccent};
  font-size: 10px;
  font-weight: 600;
`

export default ({currentStep, changeStep}) => (
  <DotWrapper>
    <Step selected={currentStep == 1} clickable={true} onClick={()=>changeStep(1)}>
      <FiShoppingCart />
    </Step>
    <MdChevronRight />
    <Step selected={currentStep == 2} clickable={currentStep>2} onClick={()=>changeStep(2)}>
      <FiTruck />
    </Step>
    <MdChevronRight />
    <Step selected={currentStep == 3} clickable={currentStep>3}  onClick={()=>changeStep(3)}>
      <FiClock />
    </Step>
    <MdChevronRight />
    <Step selected={currentStep == 4} clickable={currentStep>4}  onClick={()=>changeStep(4)}>
      <FiCreditCard />
    </Step>
    <MdChevronRight />
    <Step selected={currentStep == 5} clickable={currentStep>5} onClick={()=>changeStep(5)}>
      <FiClipboard />
    </Step>
  </DotWrapper>
)


// visibility: ${props => (props.selected ? 'visible' : 'hidden')};

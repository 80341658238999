import React from 'react'
import styled from '@emotion/styled'

import { convertToMoney } from 'utils/priceFormat'
import {
  breakpoints,
  colors,
  fonts,
  spacing,
  dimensions,
  radius
} from 'utils/styles'
import Coupon from './Coupon'

export default (props) => {
  const {
    subTotal, taxFee, deliveryFee,applyCoupon,invalidCoupon, total, coupon
  } = props
  return (
    <Costs>
      <SubCosts>
      <Cost>
        <span>Subtotal:</span>{' '}
        <strong>{convertToMoney(subTotal)}</strong>
      </Cost>
      <Cost>
        <span>Taxes:</span> <strong>{convertToMoney(taxFee)}</strong>
      </Cost>
      <Cost>
        <span>Delivery:</span> <strong>{convertToMoney(deliveryFee)}</strong>
      </Cost>
      <Coupon {...props} applyCoupon={applyCoupon} invalidCoupon={invalidCoupon} coupon={coupon}/>
      </SubCosts>
      <Total>
        <span>Total Price:</span>
        <strong>{convertToMoney(total)}</strong>
      </Total>
    </Costs>
  )
}

const Costs = styled('div')`
  border-top: 1px solid ${colors.brandBright};
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.sm}px;
`
const SubCosts = styled('div')`
  padding: .5rem 0
`

const Cost = styled(`div`)`
  display: flex;
  padding: 0 ${spacing.xs}px ${spacing['2xs']}px;

  :last-child {
    padding-bottom: 0;
  }

  span {
    color: ${colors.gray600};
    flex-basis: 60%;
    font-size: 0.8rem;
    text-align: right;
  }

  strong {
    color: ${colors.gray700};
    flex-basis: 40%;
    font-weight: 600;
    font-size: 0.9rem;
    text-align: right;
  }
`

const Total = styled(Cost)`
  border-top: 1px solid ${colors.brandBright};
  color: ${colors.brandDark};
  padding-top: ${spacing.sm}px;

  span {
    font-weight: bold;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  strong,
  span {
    color: inherit;
    font-size: 0.9rem;
  }
`
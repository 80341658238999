import React from 'react'
import styled from '@emotion/styled'

import { convertToMoney } from 'utils/priceFormat'
import {
  breakpoints,
  colors,
  fonts,
  spacing,
  dimensions,
  radius
} from 'utils/styles'

export default ({ total }) => (
	<Costs>
        {/*<Cost>
          <span>Subtotal:</span>{' '}
          <strong>USD $checkout.subtotalPrice</strong>
        </Cost>
        <Cost>
          <span>Taxes:</span> <strong>checkout.totalTax</strong>
        </Cost>*/}
        <Total>
          <span>Total Price:</span>
          <strong>{convertToMoney(total)}</strong>
        </Total>
    </Costs>
)

const Costs = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.sm}px;
`

const Cost = styled(`div`)`
  display: flex;
  padding: 0 ${spacing.xs}px ${spacing['2xs']}px;

  :last-child {
    padding-bottom: 0;
  }

  span {
    color: ${colors.textMild};
    flex-basis: 60%;
    font-size: 0.9rem;
    text-align: right;
  }

  strong {
    color: ${colors.lilac};
    flex-basis: 40%;
    text-align: right;
  }
`

const Total = styled(Cost)`
  border-top: 1px solid ${colors.brandBright};
  color: ${colors.brandDark};
  margin-top: ${spacing.xs}px;
  padding-top: ${spacing.sm}px;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }

  strong,
  span {
    color: inherit;
  }
`
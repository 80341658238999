import React from 'react'
import styled from '@emotion/styled'

const Row = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
`

const Col = styled('div')`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 120px;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 12px;
`

export const FormRow = ({ children, ...props }) => (
  <Row {...props}>{children}</Row>
)

export const FormCol = ({ children, ...props }) => (
  <Col {...props}>{children}</Col>
)

import React, { useState, useEffect } from 'react'

import {
  FormInput,
  FormRow,
  FormCol,
} from 'components/shared/Forms'
import Button from 'components/shared/Button'

export default ({initialData, onChange, applyCoupon, invalidCoupon, coupon}) => {
	const { promo_code } = initialData
	const [ selected, setSelected] = useState(false)
	useEffect(() => {})
	return (
		<div>
			{coupon ? 
	            <div style={{textAlign:'right',color:'green',color:'#15ae74'}}>({coupon*100}% off applied)
	            </div>
			: selected ?
			<FormRow>
	            <FormCol>
	              <FormInput error={invalidCoupon}>
	                <input
	                  type="text"
	                  required
	                  placeholder="Promo Code"
	                  name="promo_code"
	                  maxLength="250"
	                  defaultValue={promo_code}
	                  onChange={onChange}
	                />
	              </FormInput>
	            </FormCol>
	            <Button small onClick={()=>applyCoupon(promo_code)} style={{marginBottom:14,marginTop:-1}}>Redeem</Button>
	        </FormRow>
	        :
	        <div onClick={()=>setSelected(true)} style={{cursor:'pointer',textAlign:'right',fontSize:`.9rem`,color:'#15ae74'}}>
	        	Add promo code
	        </div>
			}
		</div>
	)
}
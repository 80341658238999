import React from 'react'

import { FormInput, FormRow, FormCol, FormCheckbox } from 'components/shared/Forms'

export default props => {
	const { 
		onChange, 
		onSelect,
		initialData } = props
	const {
		sameAddress,
		cardholderName, 
		b_address_1,
		b_address_2,
		b_city,
		b_state } = initialData
	return (
		<div style={{padding:`0 24px 24px 24px`}}>
		  <h5>Billing Address</h5>
		  <FormRow>
		    <FormCol>
		      <FormCheckbox
		        name="sameAddress"
		        onChange={onChange}
		        checked={sameAddress}>
		        Use the same delivery and billing address
		      </FormCheckbox>
		    </FormCol>
		  </FormRow>
		  {
		    !sameAddress &&
		    <div>
		      <FormRow>
		        <FormCol>
		          <FormInput label="Card Holder Name">
		            <input
		              type="text"
		              required
		              name="cardholderName"
		              maxLength="250"
		              defaultValue={cardholderName}
		              onChange={onChange}
		            />
		          </FormInput>
		        </FormCol>
		      </FormRow>
		      <FormRow>
		        <FormCol>
		          <FormInput label="Address line 1">
		            <input
		              type="text"
		              required
		              name="b_address_1"
		              maxLength="250"
		              defaultValue={b_address_1}
		              onChange={onChange}
		            />
		          </FormInput>
		        </FormCol>
		      </FormRow>
		      <FormRow>
		        <FormCol>
		          <FormInput label="Address line 2">
		            <input
		              type="text"
		              required
		              name="b_address_2"
		              maxLength="250"
		              defaultValue={b_address_2}
		              onChange={onChange}
		            />
		          </FormInput>
		        </FormCol>
		      </FormRow>
		      <FormRow>
		       <FormCol>
		          <FormInput label="City">
		            <input
		              type="text"
		              required
		              name="b_city"
		              maxLength="250"
		              defaultValue={b_city}
		              onChange={onChange}
		            />
		          </FormInput>
		        </FormCol>
		        <FormCol>
		          <FormInput label="State">
		            <input
		              type="text"
		              required
		              name="b_state"
		              maxLength="250"
		              defaultValue={b_state}
		              onChange={onChange}
		            />
		          </FormInput>
		        </FormCol>
		      </FormRow>        
		    </div>
		  }
		</div>
	)
}
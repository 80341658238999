import React from 'react'
import { navigate } from 'gatsby'

import Button from 'components/shared/Button'

export default ({changeStep, toggle, setContents}) => {
	const finishCheckout = () => {
		changeStep(1)
		toggle(false)
		setContents([])
		navigate('/')
	}

	return (
		<div style={{height:`100%`,display:'flex',alignItems:'center',justifyContent:'center'}}>
			<div style={{flexDirection:'column',justifyContent:'center',alignItems:'center',maxWidth:400}}>
			<div style={{marginBottom:20}}>Your order has successfully been submitted. You will recieve an email confirmation.</div>
			<Button onClick={()=>finishCheckout()} big full>Ok</Button>
			</div>
		</div>
	)
}
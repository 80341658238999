import React from 'react'
import Button from 'components/shared/Button'

export const ForwardButton = props => {
  if (!props.onClick) {
    return <div />
  }

  return (
    <Button {...props} disabled={!props.nextReady} style={{width:`100%`}}>
      {props.nextText || 'Next'}
    </Button>
  )
}

export const SubmitButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return (
    <div>
      <Button {...props} style={{width:`100%`}}>{props.submitText || 'Submit'}</Button>
    </div>
  )
}

export const FormButton = props => {
  return (
    <div style={{ position:'absolute',bottom:0,width:`100%`}}>
      {props.forward ? (
        <ForwardButton
          onClick={props.forward}
          nextReady={props.nextReady}
          nextText={props.nextText}
        />
      ) : (
        <SubmitButton
          onClick={props.submit}
          disabled={props.disabled}
          submitText={props.submitText}
        />
      )}
    </div>
  )
}

export default FormButton

import React, { useContext } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import {
  MdClose,
  MdShoppingCart,
  MdArrowBack,
  MdArrowForward
} from 'react-icons/md'

import styles from './NavList.module.scss'
import ResponsiveDropdownList from 'components/shared/ResponsiveDropdownList'
import QuickLinks from 'components/QuickLinks'
import useMediaQuery from 'utils/hooks/useMediaQuery'
import { HamburgerIcon, ChevronDown } from 'components/shared/Icons'
import { IndoorLinkItems, OutdoorLinkItems } from 'components/QuickLinks/IndoorLinks'
import Cart from 'components/Cart'
import { Button, PrimaryButton } from 'components/shared/Buttons'
import {
  breakpoints,
  colors,
  fonts,
  spacing,
  dimensions
} from 'utils/styles'
import { CartContext } from 'context/CartProvider'

const iconEntry = keyframes`
  0%, 50% {
    transform: scale(0)
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const numberEntry = keyframes`
  0%{
    transform: scale(0)
  }
  90% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.6);
  }
`

const ItemsNumber = styled(`span`)`
  align-items: center;
  background: ${colors.brandDark};
  border-radius: 50%;
  color: ${colors.lightest};
  display: flex;
  font-size: .8rem;
  font-weight: bold;
  height: 30px;
  justify-content: center;
  width: 30px;
  position: absolute;
  top: 15px !IMPORTANT;
  right: 0px;
  @media (max-width: 900px) {
    top: 5px !important;
    right: 2px !important;
  }
`

const CartToggle = styled(Button)`
  background: transparent;
  border: none;
  border-radius: 0;
  display: flex;
  
  justify-content: center;
  right: 0;
  padding: 0;
  position: relative;
  transition: all 0.5s ease;
  height: 65px;
  width: ${dimensions.headerHeight};
  color: ${colors.brand};

  @media (min-width: 900px) {
    height: 90px;
  }

  svg {
    animation: ${iconEntry} 0.75s ease forwards;
    height: 28px;
    margin: 0;
    width: 28px;
  }
  ${ItemsNumber} {
    animation: ${numberEntry} 0.5s ease forwards;
    position: absolute;
    right: 0px;
    top: 20px;
    transform: scale(0.6);
  }
`

function ProfilePlate({ transparent, isScrolled }) {
  const isTablet = useMediaQuery(`(min-width: 1200px)`)
  const { itemsInCart, toggle, mode }  = useContext(CartContext)
  return (
    <ResponsiveDropdownList
      collapseAt={200}
      collapseLabel={4}
      isTablet={isTablet}
      className={[
        styles.container,
        transparent && !isScrolled && styles.transparent,
      ].join(' ')}
      data={[
        {
          show: isTablet,
          label: (
            <div className={styles.navLink}>
              Indoor <ChevronDown className={styles.chevronDown}/>
            </div>
          ),
          links: IndoorLinkItems
        },
        {
          label: (
             <div className={styles.navLink}>
              Outdoor <ChevronDown className={styles.chevronDown}/>
            </div>
          ),
          show: isTablet,
          links: OutdoorLinkItems
        },
        
        {
          show: isTablet,
          label: (
           <Link className={styles.navLink} to="/plants">
              Plants
            </Link>
          )
        },
        {
          show: isTablet,
          label: (
           <Link className={styles.navLink} to="/pots">
              Pots
            </Link>
          )
        },
        {
          show: isTablet,
          label: (
           <Link className={styles.navLink} to="/offices">
              Offices
            </Link>
          )
        },
        {
          label: (
            <CartToggle
              aria-label={`Shopping cart with ${itemsInCart} items`}
              onClick={() => toggle()}
            >
              {mode ? (
                <MdClose />
              ) : (
                <div>
                  <MdShoppingCart />
                   {itemsInCart > 0 && (
                      <ItemsNumber>{itemsInCart}</ItemsNumber>
                    )}
                </div>
              )}
            </CartToggle>)
        }
      ]}
    />
  )
}


export default ProfilePlate

// {
//           order: 4,
//           label: (
//            <Link className={styles.navLink} to="/offices">
//               Offices
//             </Link>
//           )
//         },

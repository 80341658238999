import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import { Button } from 'components/shared/Buttons'
import {
  MdArrowBack
} from 'react-icons/md'
import { colors, spacing,breakpoints } from 'utils/styles'

const EmptyCartRoot = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`

const SadCartCopy = styled('div')`
  color: ${colors.lilac};
  margin-top: ${spacing.lg}px;
  text-align: center;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${breakpoints.mobile}px) {
    height: 350px;
  }
  p {
    margin: 0;
    font-size: .7em;
    color: ${colors.textLight};
  }
  h5 {
    font-weight: 600
  }
`

const CartImg = styled(Img)`
  height: 260px;
  width: 300px;
  @media (max-width: ${breakpoints.mobile}px) {
    display:none
  }
`

const BackLink = styled(Button)`
  font-size: 1.25rem;
  margin-bottom: 0px;
  border: 2px solid ${colors.themeAccent};
  color: ${colors.themeAccent};
  border-radius:0;
  padding: 25px;
  width: 100%;
`

const EmptyCart = ({itemsInCart, toggle}) => {
  if (itemsInCart>0) {
    return <div/>
  }
  return (
    <>
    <EmptyCartRoot>
      <SadCartCopy>
        <div>
          <h5>Your Cart is empty{' '}</h5>
          <p>Oops! Nothing in your cart yet.</p>
        </div>
        <StaticQuery
          query={graphql`
            query {
              file(relativePath: { eq: "cart-leaves.png" }) {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          `}
          render={data =>
            <CartImg fluid={data.file.childImageSharp.fluid} />
          }/>
      </SadCartCopy>
    </EmptyCartRoot>
     <BackLink accentInverse onClick={() => toggle()}>
        <MdArrowBack />
        Back to shopping
      </BackLink>
    </>
  )
}


export default EmptyCart

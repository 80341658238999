import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { colors, dimensions, radius, spacing } from 'utils/styles'

class CartIndicator extends Component {
  state = {
    visible: false,
    num: 1,
    message: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adding !== this.props.adding) {
      // clearTimeout()
      if (this.props.adding) {
        const num = this.props.itemsInCart - prevProps.itemsInCart
        const message =
          num > 1
            ? ` new items added to the cart`
            : ` new item added to the cart`;
        this.setState({
          num,
          visible: true,
          message
        })
      } 
      setTimeout(
        () => this.setState({ visible: false, message: '' }),
        3000
      )
    }
  }

  render() {
    const { visible, message, num } = this.state;

    return <CartRoot><Heading><CartIndicatorRoot visible={visible}><b>{num}</b>{message}</CartIndicatorRoot></Heading></CartRoot>
  }
}

const iconEntry = keyframes`
  0%, 50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc((100% + ${spacing.md}px) * -1));
  }
`

const Heading = styled(`header`)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
`

const CartRoot = styled(`div`)`
  background: #fefcfa;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0px;
  transform: translateX(100%);
  transition: transform 0.75s;
  width: 100%;
  will-change: transform;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  ::after {
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 250ms;
  }
`

const CartIndicatorRoot = styled(`div`)`
  background: #fefcfa;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  color: #444;
  font-size: .8rem;
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  left: 0;
  padding: ${spacing.xs}px ${spacing.sm}px;
  position: absolute;
  top: calc(${dimensions.headerHeight} + 30px);
  animation: ${iconEntry} 0.75s ease forwards;
  b {
    margin-right: 5px;
  }
`

CartIndicator.propTypes = {
  itemsInCart: PropTypes.number.isRequired,
  adding: PropTypes.bool.isRequired
}

export default CartIndicator

import React from 'react'
import { Link } from 'gatsby'

import styles from './QuickLinks.module.scss'
import { HamburgerIcon } from 'components/shared/Icons'

function QuickLink(props) {
  const { icon, children, ...rest } = props
  return (
    <Link className={styles.quickLink} {...rest}>
      {icon}
      {children}
    </Link>
  )
}

export default function QuickLinks(props) {
  const { user } = props

  return (
    <div className={styles.container}>
      <span className={styles.quickInfo} />
        <div>
          <div className={styles.quickLinksWrapper}>
            <QuickLink to="/plants">Plants</QuickLink>
            <QuickLink to="/pots">Pots</QuickLink>
            <QuickLink to="/indoor/bedroom">Bedroom</QuickLink>
            <QuickLink to="/indoor/living-room">Living Room</QuickLink>
            <QuickLink to="/indoor/kitchen">Kitchen</QuickLink>
            <QuickLink to="/outdoor/patio">Patio</QuickLink>
            <QuickLink to="/outdoor/balcony">Balcony</QuickLink>
            <QuickLink to="/offices">Offices</QuickLink>
          </div>
        </div>
    </div>
  )
}









import React from 'react'

export const QualityIcon = props => (
	<svg {...props} viewBox="0 0 512 512">
	  <path fill="currentColor" d="M442.7 32c-95.9 0-176.4 79.4-197.2 185.7C210.5 145.1 144.8 96 69.3 96H0v16c0 132.3 90.9 240 202.7 240H240v120c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V288h37.3C421.1 288 512 180.3 512 48V32h-69.3zm-240 288C113 320 39.2 235.2 32.5 128h36.8c89.7 0 163.4 84.8 170.2 192h-36.8zm106.6-64h-36.8C279.2 148.8 353 64 442.7 64h36.8c-6.7 107.2-80.5 192-170.2 192z"/>
	</svg>
)


export const ShippingIcon = props => (
	<svg {...props} viewBox="0 0 576 512">
	 <path fill="currentColor" d="M575.6 309.8l-5.1-15.2c-1.4-4.2-5.9-6.5-10.1-5.1L526 301.1 451.5 77.9c-2.7-8.1-8.4-14.7-16-18.5-7.7-3.8-16.3-4.4-24.4-1.7L176.3 136l-18-50.7C146.9 53.3 116.7 32 82.8 32H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h74.8c20.3 0 38.4 12.8 45.2 31.9l96.7 271.9c-23.3 17.2-37.2 46.5-31.4 78.9 5.6 31.3 30.4 57 61.5 63.5 51.7 10.7 97.2-28.4 97.2-78.2 0-13.1-3.4-25.2-9-36.1l227.5-76c4.2-1.4 6.5-5.9 5.1-10.1zM187 166.1l106.4-35.5 25 74.9c1.4 4.2 5.9 6.5 10.1 5.1l15.2-5.1c4.2-1.4 6.5-5.9 5.1-10.1l-25-74.9L421.2 88l74.5 223.3-174.4 58.1C307.6 358.7 290.7 352 272 352c-6.3 0-12.3.9-18.1 2.3L187 166.1zM272 480c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"/>
	</svg>
)

export const ReturnIcon = props => (
	<svg {...props} viewBox="0 0 608 512">
    <path fill="currentColor" d="M606.4 143.8L557.5 41c-2.7-5.6-8.1-9-13.9-9C543 32 304 64 304 64S65 32 64.4 32c-5.8 0-11.2 3.5-13.9 9L1.6 143.8c-4.4 9.2.3 20.2 9.6 23l49.5 14.9V393c0 14.7 9.5 27.5 23 31l205.4 54.1c13 3.4 23.7 1.5 29.5 0L524.2 424c13.5-3.6 23-16.4 23-31V181.7l49.5-14.9c9.4-2.8 14-13.8 9.7-23zM73 65.3l180.9 24.3-57.1 99.8-159.9-48.1 36.1-76zm18.2 125.6C208.3 226.1 200.5 224 203.6 224c5.4 0 10.5-2.9 13.3-7.9l71.9-125.5V445L91.2 393V190.9zM516.8 393l-197.6 52V90.5L391.1 216c2.9 5 8 7.9 13.3 7.9 3.1 0-5 2.1 112.4-33.1V393zM411.3 189.3l-57.1-99.8L535 65.3l36.1 76-159.8 48z"/>
	</svg>
)

export const BikeIcon = props => (
  <svg {...props} viewBox="0 0 640 512">
    <path fill="currentColor" d="M512.303 192c-19.586-.047-38.147 4.313-54.759 12.132L373.508 71.439A16 16 0 0 0 359.991 64h-67.998c-6.627 0-12 5.373-12 12v8c0 6.627 5.372 12 12 12h59.193l40.532 64H255.994v-20c0-6.627-5.372-12-12-12h-83.998c-8.836 0-16 7.163-16 16s7.163 16 16 16h55.999l-31.808 44.969c-17.085-8.362-36.303-13.035-56.622-12.968C56.937 192.234-.001 249.37 0 320c.001 70.692 57.307 128 127.997 128 59.641 0 109.755-40.793 123.964-96h52.031a16.001 16.001 0 0 0 13.107-6.824l100.744-143.924 12.677 20.018c-28.385 23.449-46.487 58.903-46.531 98.587-.077 69.963 56.843 127.499 126.801 128.138 70.559.644 128.101-55.842 129.193-125.995 1.099-70.503-57.17-129.829-127.68-130zM127.997 416c-52.933 0-95.998-43.065-95.998-96s43.064-96 95.998-96c13.307 0 25.989 2.724 37.521 7.639L98.89 326.824C91.47 337.423 99.083 352 111.997 352h106.506c-13.207 37.248-48.788 64-90.506 64zm95.998-96h-81.268l49.744-71.065c19.354 17.575 31.524 42.925 31.524 71.065zm71.668 0h-39.669c0-39.04-17.483-73.992-45.04-97.47L232.325 192H385.26l-89.597 128zm212.533 95.927c-50.058-1.938-90.528-42.677-92.154-92.747-.961-29.57 11.533-56.303 31.81-74.546l52.759 83.306c3.546 5.599 10.959 7.263 16.558 3.717l6.758-4.281c5.599-3.546 7.263-10.96 3.717-16.558l-52.785-83.346c11.427-4.811 23.972-7.473 37.128-7.473 52.933 0 95.998 43.065 95.998 96 .001 54.194-45.136 98.043-99.789 95.928z"/>
  </svg>
)

export const MoneyIcon = props => (
  <svg {...props} viewBox="0 0 16 12">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-566.000000, -554.000000)">
        <g transform="translate(430.000000, 451.000000)">
          <g transform="translate(0.000000, 23.000000)">
            <g transform="translate(136.000000, 0.000000)">
              <g transform="translate(0.000000, 80.000000)">
                <path d="M2.66666667,1.33320507 C2.66666667,0.59689624 3.26547492,0 4.00000851,0 L14.6666582,0 C15.4030425,0 16,0.598040084 16,1.33227962 L16,8.00105371 C16,8.73685143 15.4012431,9.33333333 14.6649313,9.33333333 L13.3333333,9.33333333 L13.3333333,10.6677204 C13.3333333,11.4035181 12.7345251,12 11.9999915,12 L1.33334184,12 C0.596957477,12 0,11.4019599 0,10.6677204 L0,3.99894629 C0,3.26314857 0.598808254,2.66666667 1.33334184,2.66666667 L2.66666667,2.66666667 L2.66666667,1.33320507 Z M11.9999915,2.66666667 C12.7363759,2.66666667 13.3333333,3.26470675 13.3333333,3.99894629 L13.3333333,8 L14.6649313,8 C14.6659994,8 14.6666667,1.33227962 14.6666667,1.33227962 C14.6666667,1.33387845 4.00000851,1.33333333 4.00000851,1.33333333 C4.00055388,1.33333333 4.00042797,2.08451039 4.00024851,2.66666667 L11.9999915,2.66666667 Z M1.33333333,4 L1.33333333,10.6666667 L12,10.6666667 L12,4 L1.33333333,4 Z M2,9.00312297 C2,8.81730328 2.15032506,8.66666667 2.33025932,8.66666667 L3.66974068,8.66666667 C3.85213786,8.66666667 4,8.81445106 4,9.00312297 L4,9.6635437 C4,9.84936338 3.84967494,10 3.66974068,10 L2.33025932,10 C2.14786214,10 2,9.85221561 2,9.6635437 L2,9.00312297 Z M9.33333333,5.00312297 C9.33333333,4.81730328 9.48365839,4.66666667 9.66359266,4.66666667 L11.003074,4.66666667 C11.1854712,4.66666667 11.3333333,4.81445106 11.3333333,5.00312297 L11.3333333,5.6635437 C11.3333333,5.84936338 11.1830083,6 11.003074,6 L9.66359266,6 C9.48119547,6 9.33333333,5.85221561 9.33333333,5.6635437 L9.33333333,5.00312297 Z M6.66666667,9.33333333 C5.56209717,9.33333333 4.66666667,8.43790283 4.66666667,7.33333333 C4.66666667,6.22876383 5.56209717,5.33333333 6.66666667,5.33333333 C7.77123617,5.33333333 8.66666667,6.22876383 8.66666667,7.33333333 C8.66666667,8.43790283 7.77123617,9.33333333 6.66666667,9.33333333 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const FacebookIcon = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
    />
  </svg>
)

export const TwitterIcon = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
    />
  </svg>
)

export const InstagramIcon = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M440.3 203.5c-15 0-28.2 6.2-37.9 15.9-35.7-24.7-83.8-40.6-137.1-42.3L293 52.3l88.2 19.8c0 21.6 17.6 39.2 39.2 39.2 22 0 39.7-18.1 39.7-39.7s-17.6-39.7-39.7-39.7c-15.4 0-28.7 9.3-35.3 22l-97.4-21.6c-4.9-1.3-9.7 2.2-11 7.1L246.3 177c-52.9 2.2-100.5 18.1-136.3 42.8-9.7-10.1-23.4-16.3-38.4-16.3-55.6 0-73.8 74.6-22.9 100.1-1.8 7.9-2.6 16.3-2.6 24.7 0 83.8 94.4 151.7 210.3 151.7 116.4 0 210.8-67.9 210.8-151.7 0-8.4-.9-17.2-3.1-25.1 49.9-25.6 31.5-99.7-23.8-99.7zM129.4 308.9c0-22 17.6-39.7 39.7-39.7 21.6 0 39.2 17.6 39.2 39.7 0 21.6-17.6 39.2-39.2 39.2-22 .1-39.7-17.6-39.7-39.2zm214.3 93.5c-36.4 36.4-139.1 36.4-175.5 0-4-3.5-4-9.7 0-13.7 3.5-3.5 9.7-3.5 13.2 0 27.8 28.5 120 29 149 0 3.5-3.5 9.7-3.5 13.2 0 4.1 4 4.1 10.2.1 13.7zm-.8-54.2c-21.6 0-39.2-17.6-39.2-39.2 0-22 17.6-39.7 39.2-39.7 22 0 39.7 17.6 39.7 39.7-.1 21.5-17.7 39.2-39.7 39.2z"
    />
  </svg>
)

export const GoogleIcon = props => (
  <svg {...props} viewBox="0 0 488 512">
    <path
      fill="currentColor"
      d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
    />
  </svg>
)

export const PersonIcon = props => (
  <svg {...props} viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"
    />
  </svg>
)

export const CalendarIcon = props => (
  <svg {...props} viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"
    />
  </svg>
)

export const EmptyIcon = props => (
  <svg {...props} viewBox="0 0 80 80">
    <g fill="none" fillRule="evenodd">
      <circle fill="#F5F5F5" cx="40" cy="40" r="40" />
      <path
        d="M44.59 43.922c.567-.68-.177.67.072-.123 2.193-2.604 2.956-6.5 2.956-9.02 0-7.716-6.256-13.97-13.971-13.97-7.716 0-13.971 6.254-13.971 13.97 0 7.716 6.255 13.971 13.97 13.971 2.773 0 6.93-1.06 9.075-2.937.57-.142 12.473 14.655 12.473 14.655a2.928 2.928 0 0 0 4.14-4.14L44.59 43.922z"
        stroke="#A9B0B7"
        fill="#E2E5E7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle stroke="#A9B0B7" fill="#FFF" cx="34" cy="35" r="10" />
      <path
        d="M25 58c0-.667.077-1.41.333-1.667.257-.256 1-.333 1.667-.333-.667 0-1.41-.077-1.667-.333-.256-.257-.333-1-.333-1.667 0 .667-.077 1.41-.333 1.667-.257.256-1 .333-1.667.333.667 0 1.41.077 1.667.333.256.257.333 1 .333 1.667z"
        stroke="#4E78F1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.083 64.083c.45-.449 1.75-.583 2.917-.583-1.167 0-2.468-.134-2.917-.583-.449-.45-.583-1.75-.583-2.917 0 1.167-.134 2.468-.583 2.917-.45.449-1.75.583-2.917.583 1.167 0 2.468.134 2.917.583.449.45.583 1.75.583 2.917 0-1.167.134-2.468.583-2.917z"
        stroke="#A2CBFF"
        fill="#A2CBFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.564 29.338c0-1.806.508-2.785 1.204-3.481.696-.696 1.75-1.298 3.556-1.298-1.806 0-2.897-.527-3.593-1.223-.696-.696-1.204-1.788-1.204-3.594 0 1.806-.471 2.898-1.167 3.594-.696.696-1.825 1.223-3.632 1.223 1.807 0 2.973.602 3.67 1.298.695.696 1.166 1.675 1.166 3.48z"
        stroke="#A2CBFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5 16c0-.833.513-1.346.833-1.667.321-.32.834-.833 1.667-.833-.833 0-1.346-.512-1.667-.833-.32-.321-.833-.834-.833-1.667 0 .833-.513 1.346-.833 1.667-.321.32-.834.833-1.667.833.833 0 1.346.512 1.667.833.32.321.833.834.833 1.667z"
        stroke="#E2E5E7"
        fill="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const MapIcon = props => (
  <svg {...props} viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M560.02 32c-1.96 0-3.98.37-5.96 1.16L384.01 96H384L212 35.28A64.252 64.252 0 0 0 191.76 32c-6.69 0-13.37 1.05-19.81 3.14L20.12 87.95A32.006 32.006 0 0 0 0 117.66v346.32C0 473.17 7.53 480 15.99 480c1.96 0 3.97-.37 5.96-1.16L192 416l172 60.71a63.98 63.98 0 0 0 40.05.15l151.83-52.81A31.996 31.996 0 0 0 576 394.34V48.02c0-9.19-7.53-16.02-15.98-16.02zM30.63 118.18L176 67.61V387.8L31.91 441.05l-1.28-322.87zM208 387.71V67.8l160 56.48v319.91l-160-56.48zm192 56.68V124.2l144.09-53.26 1.28 322.87L400 444.39z"
    />
  </svg>
)

export const ArticleIcon = props => (
  <svg {...props} viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M552 64H88c-13.234 0-24 10.767-24 24v8H24c-13.255 0-24 10.745-24 24v280c0 26.51 21.49 48 48 48h504c13.233 0 24-10.767 24-24V88c0-13.233-10.767-24-24-24zM32 400V128h32v272c0 8.822-7.178 16-16 16s-16-7.178-16-16zm512 16H93.258A47.897 47.897 0 0 0 96 400V96h448v320zm-404-96h168c6.627 0 12-5.373 12-12V140c0-6.627-5.373-12-12-12H140c-6.627 0-12 5.373-12 12v168c0 6.627 5.373 12 12 12zm20-160h128v128H160V160zm-32 212v-8c0-6.627 5.373-12 12-12h168c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H140c-6.627 0-12-5.373-12-12zm224 0v-8c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0-64v-8c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0-128v-8c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0 64v-8c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12z"
    />
  </svg>
)

export const OutArrow = props => (
  <svg {...props} viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M448 241.823V464c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h339.976c10.691 0 16.045 12.926 8.485 20.485l-24 24a12.002 12.002 0 0 1-8.485 3.515H54a6 6 0 0 0-6 6v340a6 6 0 0 0 6 6h340a6 6 0 0 0 6-6V265.823c0-3.183 1.264-6.235 3.515-8.485l24-24c7.559-7.56 20.485-2.206 20.485 8.485zM564 0H428.015c-10.658 0-16.039 12.93-8.485 20.485l48.187 48.201-272.202 272.202c-4.686 4.686-4.686 12.284 0 16.971l22.627 22.627c4.687 4.686 12.285 4.686 16.971 0l272.201-272.201 48.201 48.192c7.513 7.513 20.485 2.235 20.485-8.485V12c0-6.627-5.373-12-12-12z"
    />
  </svg>
)

export const RightChevron = props => (
  <svg {...props} viewBox="0 0 7 12">
    <path
      d="M.27 10.46a.89.89 0 0 0 0 1.28c.36.35.94.35 1.3 0l5.16-5.1a.89.89 0 0 0 0-1.28L1.57.26a.93.93 0 0 0-1.3 0 .89.89 0 0 0 0 1.28L4.5 6 .27 10.46z"
      fillRule="evenodd"
    />
  </svg>
)

export const RightArrow = props => (
  <svg {...props} viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
    />
  </svg>
)

export const LeftArrow = props => (
  <svg {...props} viewBox="0 0 448 512">
    <path fill="currentColor" d="M231.536 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113L238.607 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/>
  </svg>
)


export const BoxIcon = props => (
  <svg {...props} viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"
    />
  </svg>
)

export const MagGlass = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"
    />
  </svg>
)

export const PinIcon = props => (
  <svg {...props} viewBox="0 0 288 512">
    <path
      fill="currentColor"
      d="M144 0C64.47 0 0 64.47 0 144c0 74.05 56.1 134.33 128 142.39v206.43l11.01 16.51c2.38 3.56 7.61 3.56 9.98 0L160 492.82V286.39c71.9-8.05 128-68.34 128-142.39C288 64.47 223.53 0 144 0zm0 256c-61.76 0-112-50.24-112-112S82.24 32 144 32s112 50.24 112 112-50.24 112-112 112zm0-192c-44.12 0-80 35.89-80 80 0 8.84 7.16 16 16 16s16-7.16 16-16c0-26.47 21.53-48 48-48 8.84 0 16-7.16 16-16s-7.16-16-16-16z"
    />
  </svg>
)

export const CalendarIcon2 = props => (
  <svg {...props} viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"
    />
  </svg>
)

export const TypeIcon = props => (
  <svg {...props} viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M512 224H128c-17.7 0-32 14.4-32 32v248c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-56h384v56c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V256c0-17.6-14.3-32-32-32zm0 192H128v-64h384v64zm0-96H128v-64h384v64zm98.6-201.7L338.6 3.7c-11.8-5-25.3-5-37.2 0l-272 114.6C11.5 125.8 0 143.2 0 162.5V504c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V162.5c0-6.5 3.8-12.2 9.8-14.8l272-114.6c3.9-1.7 8.5-1.7 12.4 0l272 114.6c6 2.5 9.8 8.3 9.8 14.8V504c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V162.5c0-19.3-11.5-36.7-29.4-44.2z"
    />
  </svg>
)

export const DeliveryIcon = props => (
  <svg {...props} viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M100.4 414.6l213.4-68.2c16.8-5.4 26.1-23.4 20.7-40.2L276 123.3c-5.4-16.9-23.5-26.2-40.2-20.8L22.5 170.7C5.7 176-3.7 194 1.7 210.9l58.4 182.9c5.3 16.6 23.2 26.2 40.3 20.8zm53.7-252.4l19.5 61-30.4 9.8-19.5-61 30.4-9.8zm-61 19.5l29.3 91.4 91.4-29.2-29.2-91.4 60.9-19.5L304 315.9 90.6 384.1 32.2 201.2l60.9-19.5zM416 32h224V0H416c-35.3 0-64 28.7-64 64v307.9L5.9 480.8c-4.3 1.2-6.8 5.6-5.6 9.8L4.5 506c1.2 4.3 5.6 6.8 9.8 5.6L367.2 400H416c0 61.9 50.1 112 112 112s112-50.1 112-112-50.1-112-112-112c-50.6 0-92.8 33.9-106.7 80H384V64c0-17.6 14.4-32 32-32zm112 288c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80z"
    />
  </svg>
)

export const ChevronRight = props => (
  <svg {...props} viewBox="0 0 256 512">
    <path
      fill="currentColor"
      d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
    />
  </svg>
)

export const ChevronLeft = props => (
  <svg {...props} viewBox="0 0 256 512">
    <path
      fill="currentColor"
      d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"
    />
  </svg>
)

export const ChevronDown = props => (
  <svg {...props} viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
    />
  </svg>
)

export const CheckMark = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
    />
  </svg>
)

export const WarningIcon = props => (
  <svg {...props} viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
    />
  </svg>
)

export const ErrorIcon = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
    />
  </svg>
)

export const SuccessIcon = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
    />
  </svg>
)

export const ReportIcon = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M344.348 74.667C287.742 74.667 242.446 40 172.522 40c-28.487 0-53.675 5.322-76.965 14.449C99.553 24.713 75.808-1.127 46.071.038 21.532.999 1.433 20.75.076 45.271-1.146 67.34 12.553 86.382 32 93.258V500c0 6.627 5.373 12 12 12h8c6.627 0 12-5.373 12-12V378.398c31.423-14.539 72.066-29.064 135.652-29.064 56.606 0 101.902 34.667 171.826 34.667 51.31 0 91.933-17.238 130.008-42.953 6.589-4.45 10.514-11.909 10.514-19.86V59.521c0-17.549-18.206-29.152-34.122-21.76-36.78 17.084-86.263 36.906-133.53 36.906zM48 28c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.972-20-20 8.972-20 20-20zm432 289.333C456.883 334.03 415.452 352 371.478 352c-63.615 0-108.247-34.667-171.826-34.667-46.016 0-102.279 10.186-135.652 26V106.667C87.117 89.971 128.548 72 172.522 72c63.615 0 108.247 34.667 171.826 34.667 45.92 0 102.217-18.813 135.652-34.667v245.333z"
    />
  </svg>
)

export const MapMarkerIcon = props => (
  <svg {...props} viewBox="0 0 384 512">
    <path
      fill="currentColor"
      d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"
    />
  </svg>
)

export const GreenCheckIcon = props => (
  <svg {...props} viewBox="0 0 162 162">
    <circle
      id="Oval"
      fill="#E4F7ED"
      fillRule="nonzero"
      cx="81"
      cy="81"
      r="81"
    />
    <g transform="translate(33.000000, 45.000000)" fill="#49B882">
      <path
        d="M32.60586,70.5859224 L1.4058295,39.206502 C-0.468609833,37.3212853 -0.468609833,34.2646205 1.4058295,32.3792151 L8.19389863,25.5519283 C10.068338,23.6665229 13.1077159,23.6665229 14.9821553,25.5519283 L35.9999883,46.6904184 L81.0178447,1.41391256 C82.8922841,-0.471304186 85.931662,-0.471304186 87.8061014,1.41391256 L94.5941705,8.24119944 C96.4686098,10.1264162 96.4686098,13.183081 94.5941705,15.0684863 L39.3941166,70.586111 C37.5194898,72.4713278 34.4802993,72.4713278 32.60586,70.5859224 Z"
        id="Path"
      />
    </g>
  </svg>
)

export const HamburgerIcon = props => (
  <svg {...props} viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
    />
  </svg>
)

export const BellIcon = props => (
  <svg {...props} viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M224 480c-17.66 0-32-14.38-32-32.03h-32c0 35.31 28.72 64.03 64 64.03s64-28.72 64-64.03h-32c0 17.65-14.34 32.03-32 32.03zm209.38-145.19c-27.96-26.62-49.34-54.48-49.34-148.91 0-79.59-63.39-144.5-144.04-152.35V16c0-8.84-7.16-16-16-16s-16 7.16-16 16v17.56C127.35 41.41 63.96 106.31 63.96 185.9c0 94.42-21.39 122.29-49.35 148.91-13.97 13.3-18.38 33.41-11.25 51.23C10.64 404.24 28.16 416 48 416h352c19.84 0 37.36-11.77 44.64-29.97 7.13-17.82 2.71-37.92-11.26-51.22zM400 384H48c-14.23 0-21.34-16.47-11.32-26.01 34.86-33.19 59.28-70.34 59.28-172.08C95.96 118.53 153.23 64 224 64c70.76 0 128.04 54.52 128.04 121.9 0 101.35 24.21 138.7 59.28 172.08C421.38 367.57 414.17 384 400 384z"
    />
  </svg>
)

export const MailIcon = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"
    />
  </svg>
)

export const CrossIcon = props => (
  <svg {...props} viewBox="0 0 320 512">
    <path
      fill="currentColor"
      d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
    />
  </svg>
)

export const DesktopIcon = props => (
  <svg {...props} viewBox="0 0 576 512">
    <path
      fill="currentColor"
      d="M528 0H48C21.5 0 0 21.5 0 48v288c0 26.5 21.5 48 48 48h192l-24 96h-72c-8.8 0-16 7.2-16 16s7.2 16 16 16h288c8.8 0 16-7.2 16-16s-7.2-16-16-16h-72l-24-96h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM249 480l16-64h46l16 64h-78zm295-144c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h480c8.8 0 16 7.2 16 16v288z"
    />
  </svg>
)

export const DollyIcon = props => (
  <svg {...props} viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M208 352h384c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16h-48V80c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16v256c0 8.8 7.2 16 16 16zM416 96h96v96h-96V96zm0 128h160v96H416v-96zM224 96h160v224H224V96zm408 320H128V8c0-4.4-3.6-8-8-8H8C3.6 0 0 3.6 0 8v16c0 4.4 3.6 8 8 8h88v408c0 4.4 3.6 8 8 8h58.9c-1.8 5-2.9 10.4-2.9 16 0 26.5 21.5 48 48 48s48-21.5 48-48c0-5.6-1.2-11-2.9-16H451c-1.8 5-2.9 10.4-2.9 16 0 26.5 21.5 48 48 48s48-21.5 48-48c0-5.6-1.2-11-2.9-16H632c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-424 64c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm288 0c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16z"
    />
  </svg>
)

export const PeopleIcon = props => (
  <svg {...props} viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z"
    />
  </svg>
)

export const HandsIcon = props => (
  <svg {...props} viewBox="0 0 640 512">
    <path
      fill="currentColor"
      d="M638.9 209.7l-8-13.9c-2.2-3.8-7.1-5.1-10.9-2.9l-108 63V240c0-26.5-21.5-48-48-48H320v62.2c0 16-10.9 30.8-26.6 33.3-20 3.3-37.4-12.2-37.4-31.6v-94.3c0-13.8 7.1-26.6 18.8-33.9l33.4-20.9c11.4-7.1 24.6-10.9 38.1-10.9h103.2l118.5-67c3.8-2.2 5.2-7.1 3-10.9l-8-14c-2.2-3.8-7.1-5.2-10.9-3l-111 63h-94.7c-19.5 0-38.6 5.5-55.1 15.8l-33.5 20.9c-17.5 11-28.7 28.6-32.2 48.5l-62.5 37c-21.6 13-35.1 36.7-35.1 61.9v38.6L4 357.1c-3.8 2.2-5.2 7.1-3 10.9l8 13.9c2.2 3.8 7.1 5.2 10.9 3L160 305.3v-57.2c0-14 7.5-27.2 19.4-34.4l44.6-26.4v65.9c0 33.4 24.3 63.3 57.6 66.5 38.1 3.7 70.4-26.3 70.4-63.7v-32h112c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16h-24v36c0 19.8-16 35.8-35.8 35.8h-16.1v16c0 22.2-18 40.2-40.2 40.2H238.5l-115.6 67.3c-3.8 2.2-5.1 7.1-2.9 10.9l8 13.8c2.2 3.8 7.1 5.1 10.9 2.9L247.1 448h100.8c34.8 0 64-24.8 70.8-57.7 30.4-6.7 53.3-33.9 53.3-66.3v-4.7c13.6-2.3 24.6-10.6 31.8-21.7l132.2-77c3.8-2.2 5.1-7.1 2.9-10.9z"
    />
  </svg>
)

export const CalendarStarIcon = props => (
  <svg {...props} viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M149.8 369l-7.9 46.3c-1.8 10.6 2.5 21.1 11.2 27.4 4.9 3.5 10.7 5.4 16.4 5.4 4.4 0 8.9-1.1 13-3.3L224 423l41.5 21.8c9.5 5 20.8 4.2 29.5-2.1 8.7-6.3 13-16.8 11.2-27.4l-7.9-46.3 33.5-32.7c7.7-7.5 10.4-18.5 7.1-28.7s-12-17.5-22.6-19l-46.4-6.8-20.7-42.1C244.3 230 234.7 224 224 224s-20.3 6-25 15.6l-20.7 42.1-46.4 6.8c-10.6 1.5-19.3 8.8-22.6 19s-.6 21.2 7.1 28.7l33.4 32.8zm49.6-58.1L224 261l24.6 49.9 55 8-39.8 38.9 9.4 54.8-49.2-25.8-49.2 25.9 9.4-54.8-39.8-38.9 55-8.1zM400 64h-48V8c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v56H128V8c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v56H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm16 400c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V192h384v272zm0-304H32v-48c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v48z"
    />
  </svg>
)

export const PhoneIcon = props => (
  <svg {...props} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M493.09 351.3L384.7 304.8a31.36 31.36 0 0 0-36.5 8.9l-44.1 53.9A350 350 0 0 1 144.5 208l53.9-44.1a31.35 31.35 0 0 0 8.9-36.49l-46.5-108.5A31.33 31.33 0 0 0 125 .81L24.2 24.11A31.05 31.05 0 0 0 0 54.51C0 307.8 205.3 512 457.49 512A31.23 31.23 0 0 0 488 487.7L511.19 387a31.21 31.21 0 0 0-18.1-35.7zM456.89 480C222.4 479.7 32.3 289.7 32.1 55.21l99.6-23 46 107.39-72.8 59.5C153.3 302.3 209.4 358.6 313 407.2l59.5-72.8 107.39 46z"
    />
  </svg>
)

import React from 'react'
import styled from '@emotion/styled'
import {
  MdErrorOutline
} from 'react-icons/md'

import {
  breakpoints,
  colors,
  fonts,
  spacing,
  dimensions,
  radius
} from 'utils/styles'

export default ({errors}) => (
	<Errors show={errors.length}>
		<ErrorSign>
		  <MdErrorOutline />
		</ErrorSign>
		<ErrorMsgs>
		  {errors.map(error => (
		    <li
		      key={error.field}
		      dangerouslySetInnerHTML={{ __html: error.msg }}
		    />
		  ))}
		</ErrorMsgs>
	</Errors>
)

const Errors = styled(`div`)`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  margin-top: ${spacing.xs}px;
  width: 100%;
`

const ErrorSign = styled(`div`)`
  align-items: center;
  background: ${colors.error};
  border-radius: ${radius.default}px 0 0 ${radius.default}px;
  color: ${colors.lightest};
  display: flex;
  flex-basis: 40px;
  justify-content: center;
  svg {
    height: 20px;
    width: 20px;
  }
`

const ErrorMsgs = styled(`ul`)`
  border: 1px dashed ${colors.error};
  border-left: none;
  border-radius: 0 ${radius.default}px ${radius.default}px 0;
  color: ${colors.error};
  flex-grow: 1;
  margin: 0;
  padding: ${spacing.xs}px;
  padding-left: ${spacing.xl}px;
`
import React, { useState } from 'react'
import {
  SingleDatePicker
} from 'react-dates'

import './DatePicker.scss'

// prevents undefined modifiers from crashing functions
const safeModifiers = modifiers => {
  return modifiers ? modifiers : new Set()
}

export default function DatePicker(props) {
  const {
    children,
    collapseAt = 800, //px
    collapseLabel = 0,
    sidebarAt = collapseAt,
    data = [],
    type = 'range',
    ...containerProps
  } = props
  const [numMonths, setMonths] = useState(2)

  return (
    <div {...containerProps}>
      <SingleDatePicker {...props} />
    </div>
  )
}

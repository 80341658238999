import React from 'react'
import styled from '@emotion/styled'

const FormContainer = styled('div')`
  max-width: ${props => props.width || '360px'};
  width: 100%;
  margin: 32px auto;
`

export default ({ children, ...props }) => (
  <FormContainer {...props}>{children}</FormContainer>
)

import React, { Component } from 'react'
import styled from '@emotion/styled'

import { WarningIcon, ErrorIcon, SuccessIcon } from 'components/shared/Icons'
import { colors, fonts } from 'utils/styles'

const AlertBox = styled('div')`
  margin: 10px 0;
  border-radius: 4px;
  box-sizing: border-box;
  color: ${colors.brand};
  padding: 15px 17px;
  background-color: ${colors.brandLight};
  font-weight: 600;
  font-size: 11px;
  max-width: 350px;
  font-family: ${fonts.body};

  &.warning {
    color: #e39f65;
    background-color: #f9f6ee;
  }
  &.success {
    color: #49b882;
    background-color: #e4f7ed;
  }
  &.error {
    background-color: #faeff5;
    color: #e365a6;
  }
`

const AlertHeader = styled('div')`
  display: flex;
  height: 20px;
  align-items: center;
  margin-bottom: 5px;
  svg {
    height: 14px;
  }
`

const AlertTitle = styled('div')`
  margin-left: 10px;
  display: flex;
  height: 20px;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: inherit;
  text-transform: uppercase;
`

const AlertBody = styled('p')`
  font-size: 14px;
  margin-bottom: 0;
  text-align: left;
`

export default ({ type, message, visible, className, ...props }) => (
  <>
    {visible && (
      <AlertBox className={[type, className].join(' ')}>
        <AlertHeader>
          {type == 'warning' ? (
            <WarningIcon />
          ) : type == 'error' ? (
            <ErrorIcon />
          ) : (
            <SuccessIcon />
          )}
          <AlertTitle>{type}</AlertTitle>
        </AlertHeader>
        <AlertBody>{message}</AlertBody>
      </AlertBox>
    )}
  </>
)

import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../scss/bloomed.scss'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import Cart from 'components/Cart/Cart'
import { CartIndicator } from 'components/Cart'
import PageContent from './PageContent'
import ProductsProvider from 'context/ProductsProvider'
import CartProvider, { CartContext } from 'context/CartProvider'

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`

const ViewPort = styled(`div`)`
  width: 100vw;
  max-width: 100vw;
  position: relative;
  animation: ${fadeIn} 0.6s ease forwards;
`

export default class Layout extends React.Component {
  render() {
    const {
      location,
      children,
      showBeacon = false,
      displayFooter = true,
      navbarProps = {},
      viewportProps = {},
      ...childProps
    } = this.props

    return (
      <ProductsProvider>
        <CartProvider>
          <ViewPort {...viewportProps}>
              <CartContext.Consumer>
                {({ itemsInCart, total, mode, toggle, cart, isAdding, lastItem, setContents }) => {
                return (
                  <Cart itemsInCart={itemsInCart}
                        total={total}
                        mode={mode}
                        toggle={toggle}
                        isAdding={isAdding}
                        lastItem={lastItem}
                        setContents={setContents} />
                )}}
              </CartContext.Consumer>
            <PageContent
              location={location}
            >
               
              
              <Navbar location={location} {...navbarProps} />
                {React.Children.map(children, child =>
                  React.cloneElement(child, {
                    ...childProps,
                  })
                )}
              <Footer hideFooter={!displayFooter} />
            </PageContent>
          </ViewPort>
        </CartProvider>
      </ProductsProvider>
    );
  }
}

import React, { useState, useEffect, useLayoutEffect } from 'react'
import useResizeObserver from 'use-resize-observer'
import Sidebar from 'components/shared/Sidebar'
import DropdownMenu from 'components/shared/DropdownMenu'
import NavMenu from 'components/shared/NavMenu'
/**
 * A dropdown list that when resized collapses into a single
 * menu icon. The menu then slides out from the side all at
 * once as opposed to individual dropdowns
 */

export default function ResponsiveDropdownList(props) {
  const {
    children,
    collapseAt = 100, //px
    collapseLabel = 0,
    sidebarAt = collapseAt,
    data = [],
    isTablet=false,
    ...containerProps
  } = props 
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isSidebar, setIsSidebar] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [ref, width] = useResizeObserver()

  useEffect(() => {
    if (width <= 1) {
      return
    }
    if (width <= collapseAt && isTablet) {
      setIsCollapsed(true)
    } else {
      setIsCollapsed(false)
    }
    if (width <= sidebarAt) {
      setIsSidebar(true)
    } else {
      setIsSidebar(false)
    }
  }, [width])

  return (
    <div ref={ref} {...containerProps}>
      {data.map(({ label, links,item, show }, index) => {
        if (show === false) {
          return null
        }
        return (
          <NavMenu
            label={label}
            onToggle={val => {
              item && setIsOpen(true)
            }}
            renderLabel={isCollapsed ? index === collapseLabel : true}
            renderItem={!isSidebar}
            key={`item-${index}`}
            links={links}
          />
        )
      })}
      {isSidebar && (
        <Sidebar isOpen={isOpen} onClose={() => setIsOpen(false)} direction="left">
          {data
            .slice()
            .filter(({ show }) => show !== false)
            .sort((a, b) => a.order - b.order)
            .map(({ item }, index) => (
              <div key={`item-${index}`}>{item}</div>
            ))}
        </Sidebar>
      )}
    </div>
  )
}

import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import * as routes from 'constants/routes'

import styles from './Footer.module.scss'

export default ({ hideFooter }) => (
  <div>
    {!hideFooter && (
      <footer className={styles.container}>
        <div className={styles.content}>
          <div className={styles.inner}>
            <div className={styles.section}>
              <div className={styles.header}>Company</div>
              {/*<Link to="/" className={styles.footerLink}>
                Contact
              </Link>
              <Link to="/" className={styles.footerLink}>
                Feedback
              </Link>*/}
              <Link to="/legal/terms" className={styles.footerLink}>
                Terms
              </Link>
              <Link to="/legal/privacy" className={styles.footerLink}>
                Privacy
              </Link>
            </div>
            <div className={styles.section}>
              <div className={styles.header}>Indoor Plants</div>
              <Link to="/indoor/bedroom" className={styles.footerLink}>
                Bedroom
              </Link>
              <Link to="/indoor/living-room" className={styles.footerLink}>
                Living Room
              </Link>
              <Link to="/indoor/kitchen" className={styles.footerLink}>
                Kitchen
              </Link>
            </div>
            <div className={styles.section}>
              <div className={styles.header}>Outdoor Plants</div>
              <Link to="/outdoor/balcony" className={styles.footerLink}>
                Balcony
              </Link>
              <Link to="/outdoor/patio" className={styles.footerLink}>
                Patio
              </Link>
              {/*<Link to="/outdoor/window" className={styles.footerLink}>
                Window 
              </Link>*/}
            </div>
            <div className={styles.section}>
              <div className={styles.header}>Social</div>
              <a
                href={`https://facebook.com/bloomedplants`}
                target="_blank"
                className={styles.footerLink}
              >
                Facebook
              </a>
              <a
                href={`https://twitter.com/bloomedplants`}
                target="_blank"
                className={styles.footerLink}
              >
                Twitter
              </a>
              <a
                href={`https://instagram.com/bloomedplants`}
                target="_blank"
                className={styles.footerLink}
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </footer>
    )}
    {/* <p style={{textAlign:'center',fontSize:`.8rem`,paddingTop:`.5rem`}}>Made with 🌱 in Rosslyn, VA</p> */}
  </div>
)

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'gatsby'

import styles from './navbar.module.scss'
import Bloomed from '../shared/Brand/Bloomed'
import { NavbarUIState } from './NavbarConfig'
import useMediaQuery from 'utils/hooks/useMediaQuery'
import NavList from './NavList'
import ResponsiveDropdownList from 'components/shared/ResponsiveDropdownList'
import { HamburgerIcon } from 'components/shared/Icons'
import QuickLinks from 'components/QuickLinks'

export default observer(function Navbar(props) {
  // NavbarUIState state take precedence
  const augmentedProps = {
    ...props,
    ...(props.disableStore ? {} : NavbarUIState.state),
  }
  const {
    className,
    style = {},
    searchBarProps = {},
    mode = 'sticky',
    hideLeft = false,
    hideRight = false,
    showSearchBar = true,
    scrollEffects = true,
    overlay = false,
    transparent = false,
  } = augmentedProps

  const ref = useRef()

  const [isScrolled, setScrolled] = useState(false)
  const [didScrollUp, setDidScrollUp] = useState(false)
  const [isStuck, setIsStuck] = useState(false)
  const [offset, setOffset] = useState(null)

  const isPhone = useMediaQuery(`(max-width: 599px)`)
  const isTablet = useMediaQuery(`(min-width: 1200px)`)

  useLayoutEffect(() => {
    if (!scrollEffects) {
      return
    }
    let lastPos = 0
    const scrollListener = () => {
      setScrolled(window.pageYOffset > 55)
    }
    document.addEventListener('scroll', scrollListener)
    return () => document.removeEventListener('scroll', scrollListener)
  }, [scrollEffects])

  function rightComponent() {
    const { rightComponent = true } = augmentedProps
    if (rightComponent !== true) {
      return rightComponent
    }
    return <NavList />
  }

  function leftComponent() {
    const { leftComponent = true } = augmentedProps
    if (leftComponent !== true) {
      return leftComponent
    }
    return (
      <div>
        {!isTablet ?
          <ResponsiveDropdownList
            collapseAt={100}
            collapseLabel={0}
            data={[
              {
                order: 0,
                label: (
                  <HamburgerIcon className={styles.hamburgerBtn} />
                ),
                item: <QuickLinks />,
              }
              ]}/>
        :
          <Link className={styles.logo} to="/">
            <Bloomed height={isPhone ? 15 : 17} />
          </Link>
        }
      </div>
    )
  }
  return (
    <>
    <div className={styles.navBanner}>
      <span>Not accepting orders. Temporarily closed for business</span>
    </div>
    <div
      ref={ref}
      style={style}
      className={[
        className,
        styles.wrapper,
        styles[mode],
        transparent ? styles.transparent : '',
        overlay ? styles.overlay : '',
        isStuck ? styles.stuck : '',
        isScrolled ? styles.scrolled : '',
        didScrollUp ? styles.scrolledUp : '',
      ].join(' ')}
    >
      <div
        className={[styles.container, styles[mode], isStuck && styles.stuck]
          .filter(Boolean)
          .join(' ')}
      >
        <div
          className={[styles.section, styles.left, hideLeft && styles.hidden]
            .filter(Boolean)
            .join(' ')}
        >
          {leftComponent()}
        </div>
        <div
          className={[
            styles.section,
            styles.center,
            !showSearchBar && styles.hidden,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          {!isTablet && <Link className={styles.logo} to="/"><Bloomed height={isPhone ? 15 : 17} /></Link>}
        </div>
        <div
          className={[styles.section, styles.right, hideRight && styles.hidden]
            .filter(Boolean)
            .join(' ')}
        >
          {rightComponent()}
        </div>
      </div>
    </div>
    </>
  )
})

import React from 'react'

import {
  FormInput,
  FormRow,
  FormCol,
} from 'components/shared/Forms'
import FormButton from 'components/Cart/elements/FormButton'

export default class DeliveryStep extends React.Component {
  render() {
    const { initialData } = this.props
    const { email, phone, f_name, l_name, zip, address_1, address_2, c_name, city, state } = initialData

    const isValid =
      email != '' &&
      phone != ''

    return (
      <>
        <div style={{padding:24,height:`100%`,overflow:`scroll`, paddingBottom:150}}>
          <h5>Contact Details</h5>
          <FormRow>
            <FormCol>
              <FormInput label="Email">
                <input
                  type="text"
                  required
                  name="email"
                  id="email"
                  maxLength="250"
                  defaultValue={email}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
            <FormCol>
              <FormInput label="Phone">
                <input
                  name="phone"
                  required
                  id="phone"
                  defaultValue={phone}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <h5>Delivery Address</h5>
          <FormRow>
            <FormCol>
              <FormInput label="First Name">
                <input
                  type="text"
                  required
                  name="f_name"
                  id="f_name"
                  maxLength="250"
                  defaultValue={f_name}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
            <FormCol>
              <FormInput label="Last Name">
                <input
                  type="text"
                  required
                  name="l_name"
                  id="l_name"
                  maxLength="250"
                  defaultValue={l_name}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
           <FormCol>
              <FormInput label="Company Name">
                <input
                  type="text"
                  required
                  name="c_name"
                  id="c_name"
                  maxLength="250"
                  defaultValue={c_name}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
           <FormCol>
              <FormInput label="Zip Code">
                <input
                  type="text"
                  required
                  name="zip"
                  id="zip"
                  maxLength="250"
                  defaultValue={zip}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
           <FormCol>
              <FormInput label="Address line 1">
                <input
                  type="text"
                  required
                  name="address_1"
                  id="address_1"
                  maxLength="250"
                  defaultValue={address_1}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
           <FormCol>
              <FormInput label="Address line 2">
                <input
                  type="text"
                  required
                  name="address_1"
                  id="address_1"
                  maxLength="250"
                  defaultValue={address_2}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>
          <FormRow>
           <FormCol>
              <FormInput label="City">
                <input
                  type="text"
                  required
                  name="city"
                  id="city"
                  maxLength="250"
                  defaultValue={city}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
            <FormCol>
              <FormInput label="State">
                <input
                  type="text"
                  required
                  name="state"
                  id="state"
                  maxLength="250"
                  defaultValue={state}
                  onChange={this.props.onChange}
                />
              </FormInput>
            </FormCol>
          </FormRow>        
        </div>
        <FormButton {...this.props} nextReady={true} />
      </>
    )
  }
}

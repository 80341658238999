import React, { useState, useContext } from 'react'
import styled from '@emotion/styled'
import { MdClose } from 'react-icons/md'

import { Input } from 'components/shared/FormElements'
import { Button } from 'components/shared/Buttons'
import { breakpoints, colors, spacing } from 'utils/styles'
import { convertToMoney } from 'utils/priceFormat'
import { CartContext } from 'context/CartProvider'

export default ({
  item,
  setCartLoading,
  amount,
  isCartLoading
}) => {
  const [quantity, setQuantity] = useState(amount)
  const { remove, update } = useContext(CartContext)

  const handleInputChange = event => {

    const value = event.target.value

    // // Make sure the quantity is always at least 1.
    const safeValue = Math.max(Number(value), 0)
    // // Otherwise, trigger the loading state and set the quantity in state.
    // //setCartLoading(true);
    setQuantity(safeValue)

    // // If the quantity is set to 0, remove the item.
    if (safeValue === 0) {
      remove(item.id)
      return
    }

    // If we get here, update the quantity.
    update(item.id, safeValue);
  }

  const handleRemoveItem = event => {
    remove(item.id)
  }

  return (
    <CartListItemRoot>
      <ImageWrapper>
        <ImageSpacer/>
        <CartImage src={item.product.images[0]} />
      </ImageWrapper>
      <Info>
        
        <div>
        <Name>{item.product.name}</Name>
        <Meta>
          {convertToMoney(item.price)}
        </Meta>
        </div>
      </Info>
      <Quantity
        aria-label="Quantity"
        id={`quantity_${item.id.substring(58, 64)}`}
        type="number"
        name="quantity"
        min="1"
        step="1"
        onChange={handleInputChange}
        onBlur={() => setQuantity(item.quantity)}
        value={quantity}
      />
      <Remove onClick={handleRemoveItem}>
        <MdClose />
      </Remove>
    </CartListItemRoot>
  )
}

const CartListItemRoot = styled('li')`
  align-items: center;
  border-bottom: 1px solid ${colors.brandLight};
  display: flex;
  justify-content: space-between;
  padding: ${spacing.md}px 0;
`

const Thumbail = styled('img')`
  flex-grow: 0;
  margin-left: ${spacing['2xs']}px;
  margin-right: ${spacing.sm}px;
`

const Info = styled('div')`
  display:flex;
  flex-grow: 1;
  flex-direction:row;
`

const Name = styled('span')`
  display: block;
  font-size: .8rem;
  line-height: 1.2;
`

const Meta = styled('span')`
  color: ${colors.textLight};
  display: block;
  font-size: 0.75rem;
  font-style: normal;
`

const Quantity = styled(Input)`
  flex-grow: 0;
  height: 44px;
  margin-right: ${spacing.xs}px;
  padding: 0 ${spacing.xs}px 0;
  text-align: center;
  width: 50px;

  @media (min-width: ${breakpoints.desktop}px) {
    width: 70px;
  }
`

const Remove = styled(Button)`
  border: 1px dotted ${colors.textLighter};
  display: flex;
  height: 44px;
  justify-content: center;
  margin-right: ${spacing['2xs']}px;
  padding: 0;
  width: 44px;

  svg {
    height: 24px;
    margin: 0;
    width: 24px;
  }
`

const ImageWrapper = styled('div')`
  height: 38px;
  width: 38px;
  -webkit-box-flex: 0;
  flex-grow: 0;
  margin-left: 4px;
  margin-right: 12px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(245, 243, 247);
  border-image: initial;
  border-radius: 2px;
  position:relative;
  overflow:hidden
`

const CartImage = styled('img')`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
  transition: opacity 0.5s ease 0s;
`
const ImageSpacer = styled('div')`
  width: 100%; 
  padding-bottom: 100%;
`

import React, { useState } from 'react'

import styles from './Review.module.scss'
import FormButton from 'components/Cart/elements/FormButton'
import { convertToMoney } from 'utils/priceFormat'
import { toDate } from 'utils/dateFormat'

export default (props) => {
	const { cart, itemsInCart, total, forward } = props
	const [loading, setLoading] = useState(false)
	const { initialData } = props
	const { 
		f_name, 
		l_name, 
		address_1, 
		address_2, 
		city, 
		state, 
		zip, 
		d_instructions,
		d_date,
		d_time,
		email,
		phone,
		promo_code,
		source } = initialData

	const shipping_name = f_name + ' ' + l_name

	
	const onSubmit = async () => {
		setLoading(true)
	    const items = cart.map(([sku, quantity]) => ({
	      type: 'sku',
	      parent: sku.id,
	      quantity
	    }))

	    let response
	    try {
    		if (window) {
		        window.analytics.track("Purchase",{
		        	items
		        })
		    }

	      response = await fetch('/.netlify/functions/orderCreate', {
	        method: 'POST',
	        body: JSON.stringify({
	          source,
	          order: {
	            currency: 'usd',
	            coupon: promo_code,
	            items,
	            shipping: {
	              name: shipping_name,
	              address: {
	                line1: address_1,
	                line2: address_2 || '',
	                city,
	                state,
	                postal_code: zip,
	                country: 'USA'
	              }
	            },
	            metadata: {
	            	d_instructions,
	            	d_date,
	            	d_time,
	            	email,
	            	phone
	            }
	          }
	        })
	      }).then(response => {
	      	response.json()	
	      	setLoading(false)
	      })
	    } catch (err) {
	      alert(err.message)
	      setLoading(false)
	    }
	    localStorage.setItem('cart', '{}')
	   	forward()
	  }

	return (
		<div>
	      <div style={{padding:24,height:`100%`}} className={styles.summary}>
	        <h5>Order Summary</h5>
	        <p>Delivering {itemsInCart} item{itemsInCart>1&&'s'} for {convertToMoney(total)}</p>
	        <h5>Delivery Date</h5>
	        <p>{toDate(d_date, 'dddd, MMMM Do')} between {d_time}</p>
	        <h5>Delivery Address</h5>
	        <div>
	        <p>{shipping_name}</p>
	        <p>{address_1}</p>
	        {address_2 && <p>address_1</p>}
	        <p>{city+', '+state+' '+zip}</p>
	        </div>
	      </div>
	      <FormButton nextReady={true} submit={onSubmit} submitText={loading ? "Submitting ..." : "Confirm Order"}/>
		</div>
	)
}
import React, { useState } from "react"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default ({className}) => {
    const [ email, setEmail ] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = e => {
        if (window) {
            window.analytics.track("Email Signup")
        }
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "newsletter", email })
      })
        .then(() => setSubmitted(true))
        .catch(error => alert(error));

      e.preventDefault();
    }

    if (submitted) {
        return (<p style={{textAlign:'center'}}>Thanks for signing up! 🌱</p>)
    }

    return (
        <form className={['field field-grouped',className].join(' ')} 
            name="newsletter"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="control control-expanded">
                <input className="input" type="email" name="email" onChange={e => setEmail(e.target.value)}  required placeholder="Your email&hellip;"/>
            </div>
            <div className="control">
                <input className="button button-primary button-block button-shadow" type="submit" value="Subscribe"/>
            </div>
        </form>
    )
}

import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Link } from 'gatsby'

import styles from './NavMenu.module.scss'
import GatsbyImage from 'components/shared/GatsbyImage'

export default (props) => {
	const {
		children,
		isRelative = true, // is this container relative
		label = 'Click Me',
		className,
		renderCustomTrigger,
		forceOpen = false,
		renderLabel = true,
		renderItem = true,
		links,
		labelContainerProps = {},

		dropdownClass,
		dropdownProps = {},
		dropdownPosition = 'bottom innerRight',

		...containerProps
	} = props

	const dropDownRef = useRef()

	const [isOpen, setOpen] = useState(forceOpen)

	const toggleDropdown = useCallback(val => {
		setOpen(prev => {
		  const newVal = typeof val === 'undefined' ? !prev : !!val
		  if (typeof props.onToggle === 'function') {
		    props.onToggle(newVal)
		  }
		  return newVal
		})
	})

	useEffect(() => {
		// When dropdown clicked, do not close it
		const handler = e => {
		  const target = dropDownRef.current
		  let curr = e.target
		  let depth = 500 // prevent an infinite loop
		  do {
		    if (curr === target) {
		      return
		    }
		    curr = curr.parentElement
		  } while (curr && depth--)
		  setOpen(false)
		}
		if (isOpen === true) {
		  document.addEventListener('click', handler)
		}
	return () => document.removeEventListener('click', handler)
	}, [isOpen])
  	return (
	    <div
	      {...containerProps}
	      className={[
	        styles.container,
	        isRelative ? styles.relative : '',
	        className,
	      ].join(' ')}
	    >
	      {renderCustomTrigger ? (
	        renderCustomTrigger(toggleDropdown)
	      ) : (
	        <div {...labelContainerProps} onClick={toggleDropdown}>
	          {renderLabel && label}
	        </div>
	      )}
	    {links && 
		<div className={[styles.headerMenuDropdown,isOpen ? styles.active : ''].join(' ')} onMouseLeave={()=>setOpen(false)}>
			<div className={styles.dropdownGroup}>
				<span className={[styles.menuLink, styles.sectionTitle].join(' ')}>Shop All:</span>
				{links.sideLinks.map((item)=>
					<Link to={item.link} className={styles.menuLink}>{item.name}</Link>
				)}
			</div>
			{links.mainLinks.map((item)=>
				<Link to={item.link} className={styles.menuLink}>
					<div className={styles.menuLinkContainer}>
						<img src={item.img}/>
						{item.name}
					</div>
				</Link>
			)}
		</div>
		}
	    </div>
	)
}
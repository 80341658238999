import React, { useContext } from 'react'
import styled from '@emotion/styled'

import CartListItem from './CartListItem'
import { colors, spacing } from 'utils/styles'
import { CartContext } from 'context/CartProvider'
import Coupon from './Coupon'
import CartCosts from './CartCosts'
import { FormButton } from 'components/Cart/elements'
import CartErrors  from 'components/Cart/CartErrors'

const CartList = (props) => {
  const { cart, total, subTotal, taxFee, deliveryFee, applyCoupon,invalidCoupon, coupon } = useContext(CartContext)
  const errors = []
  return (
    <>
      <div style={{padding:24, overflow:'scroll', height:`100%`, paddingBottom:50}}>
        <Headers>
          <span>Product</span>
          <span>Qty.</span>
          <span>Remove</span>
        </Headers>
        <CartListRoot>
          <>
          {cart.map(([sku, quantity]) => (
            <CartListItem
              key={sku.id}
              item={sku}
              amount={quantity}
            />
          ))}
          <CartErrors errors={errors}/> 
          
          </>
        </CartListRoot>

        <CartCosts 
          {...props}
          total={total}
          subTotal={subTotal} 
          taxFee={taxFee} 
          coupon={coupon}
          applyCoupon={applyCoupon}
          invalidCoupon={invalidCoupon}
          deliveryFee={deliveryFee} />
      </div>
      <FormButton nextReady={true} {...props}/>
    </>
  )
}

const CartListRoot = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 300px;
  overflow: scroll;
`

const Headers = styled(`div`)`
  border-bottom: 1px solid ${colors.brandBright};
  display: flex;
  justify-content: space-between;

  span {
    color: #444;
    flex-basis: 60px;
    flex-grow: 0;
    font-size: 0.8rem;
    padding-bottom: ${spacing.xs}px;
    text-align: center;

    &:first-of-type {
      flex-grow: 1;
      text-align: left;
    }
  }
`

export default CartList

import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import {
  MdClose,
  MdShoppingCart,
  MdArrowBack,
  MdArrowForward
} from 'react-icons/md'

import { Button } from 'components/shared/Buttons'
import {
  breakpoints,
  colors,
  fonts,
  spacing,
  dimensions
} from 'utils/styles'
import { CartContext } from 'context/CartProvider'
import { CartErrors, CartCosts, CartIndicator, EmptyCart } from 'components/Cart'
import { CartList, Delivery, Payment, Review, Success, Time } from 'components/Cart/steps'
import { ProgressBar } from 'components/Cart/elements'

const CartRoot = styled(`div`)`
  background: #fefcfa;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0px;
  transform: translateX(100%);
  transition: transform 0.75s;
  width: 100%;
  will-change: transform;
  z-index: 999999;
  display: flex;
  flex-direction: column;

  &.open {
    transform: translateX(0%);
  }

  &.closed {
    transform: translateX(100%);
  }

  ::after {
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 250ms;
  }

  &.loading {
    ::after {
      opacity: 0.9;
      pointer-events: all;
    }
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: ${dimensions.cartWidthDesktop};

    &.covered {
      display: none;
    }
  }
`

const Heading = styled(`header`)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
`

const Content = styled(`div`)`
  bottom: 0;
  overflow-y: hidden;
  position: absolute;
  top: ${dimensions.headerHeight};
  width: 100%;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${breakpoints.desktop}px) {
    ::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.brandBright};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.lilac};
    }
    ::-webkit-scrollbar-track {
      background: ${colors.brandLight};
    }
  }
`

const ItemsNumber = styled(`span`)`
  align-items: center;
  background: ${colors.brandDark};
  border-radius: 50%;
  color: ${colors.lightest};
  display: flex;
  font-size: 1.1rem;
  font-weight: bold;
  height: 36px;
  justify-content: center;
  width: 36px;
`

const ItemsInCart = styled(`div`)`
  align-items: center;
  display: flex;
  line-height: 1.2;
  text-align: right;
  margin-top: 10px;
  ${ItemsNumber} {
    margin-left: ${spacing.xs}px;
    margin-right: ${spacing.md}px;
  }
`

const iconEntry = keyframes`
  0%, 50% {
    transform: scale(0)
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

const numberEntry = keyframes`
  0%{
    transform: scale(0)
  }
  90% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.6);
  }
`

const CartToggle = styled(Button)`
  background: transparent;
  border: none;
  border-radius: 0;
  display: flex;
  justify-content: center;
  right: 0;
  padding: 0;
  position: sticky;
  top: 50px;
  transition: all 0.5s ease;
  height: 65px;
  width: ${dimensions.headerHeight};

  background: ${colors.lilac};
  color: ${colors.lightest};

  @media (min-width: 900px) {
    height: 90px;
  }

  svg {
    animation: ${iconEntry} 0.75s ease forwards;
    height: 28px;
    margin: 0;
    width: 28px;
    margin-top: 15px;
  }

  ${ItemsNumber} {
    animation: ${numberEntry} 0.5s ease forwards;
    position: absolute;
    right: 0px;
    top: 20px;
    transform: scale(0.6);
  }
`



class Cart extends React.Component {
  state = {
    isLoading: false,
    currentStep: 1,
    errors: [],
    email: '',
    phone: '',
    f_name: '',
    l_name: '',
    c_name: '',
    zip: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    d_instructions: '',
    d_date: null,
    d_time: null,
    sameAddress: true,
    cardholderName: '',
    b_address_1: '',
    b_address_2: '',
    b_city: '',
    b_state: '',
    b_zip: '',
    promo_code: '',
    source: null
  }

  _moveForward = () => {
    if (this.state.currentStep==1) {
      if (window) {
        window.analytics.track("Initiate Checkout")
      }
    }
    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  changeStep = step => {
    if (this.state.currentStep > step) {
      this.setState({currentStep:step})
    }
  }

  setSource = source => {
    this.setState({source})
  }

  onChangeDate = date => {
    this.setState({d_date:date})
  }

  onChange = event => {
    if (event.target.value == null) return
    if (event.target.type == 'checkbox') {
      this.setState({[event.target.name] : event.target.checked})
    } else {
      this.setState({ [event.target.name]: event.target.value })
    }
    this.mapShippingBilling()
  }

  mapShippingBilling = () => {
    const {
      f_name,
      l_name,
      address_1,
      address_2,
      city,
      state,
      zip,
      sameAddress
    } = this.state
    if (sameAddress) {
      const cardholderName = f_name + ' ' + l_name
      this.setState({
        cardholderName,
        b_address_1: address_1,
        b_address_2: address_2,
        b_city: city,
        b_state: state,
        b_zip: zip
      })
    }
  }

  render() {
    const { errors, currentStep } = this.state

    return (
      <CartContext.Consumer value={this.state}>
        {({ itemsInCart, total, mode, toggle, cart, isAdding, lastItem, setContents }) => {
          return (
            mode ? (<CartRoot
              className={mode ? 'open': 'closed'}>
              <Heading>
                <CartToggle
                  aria-label={`Shopping cart with ${itemsInCart} items`}
                  onClick={() => toggle()}
                >
                  <MdClose />
                </CartToggle>
                <ItemsInCart>
                  <ItemsNumber>{itemsInCart}</ItemsNumber>
                </ItemsInCart>
              </Heading>

              {itemsInCart>0 && (
                <div>
                <Content>
                  <ProgressBar changeStep={this.changeStep} currentStep={currentStep} visible={true}/>
                    {
                      currentStep == 1 ?
                       <CartList 
                        initialData={{ ...this.state }}
                        onChange={this.onChange}
                        forward={this._moveForward} /> :
                      currentStep == 2 ?
                       <Delivery 
                        initialData={{ ...this.state }}
                        onChange={this.onChange}
                        forward={this._moveForward}/> : 
                      currentStep == 3 ?
                       <Time 
                        onChangeDate={this.onChangeDate}
                        initialData={{ ...this.state }}
                        onChange={this.onChange}
                        forward={this._moveForward}/> 
                       : 
                       currentStep == 4 ?
                        <Payment 
                          onChangeDate={this.onChangeDate}
                          initialData={{ ...this.state }}
                          onSelect={this.onSelect}
                          onChange={this.onChange}
                          setSource={this.setSource}
                          forward={this._moveForward}/> 
                        :
                        currentStep == 5 ?
                        <Review 
                          initialData={{ ...this.state }}
                          cart={cart}
                          total={total}
                          forward={this._moveForward}
                          itemsInCart={itemsInCart}/>
                        :
                       <Success changeStep={this.changeStep} toggle={toggle} setContents={setContents}/>
                    }
                </Content>
                </div>
              )}
              <EmptyCart itemsInCart={itemsInCart} toggle={toggle}/>
            </CartRoot>)
          :  <CartIndicator itemsInCart={itemsInCart} adding={isAdding} lastItem={lastItem}/>)
        }}
      </CartContext.Consumer>
    )
  }
}

export default Cart
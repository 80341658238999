import React, { useState, useMemo, useEffect } from 'react'
import {
  StripeProvider,
  injectStripe,
  CardElement,
  Elements,
} from 'react-stripe-elements'
import { Formik } from 'formik'
import * as yup from 'yup'
import { observer } from 'mobx-react-lite'

import styles from './Payment.module.scss'
import { FormInput, FormRow, FormCol, FormCheckbox } from 'components/shared/Forms'
import Button from 'components/shared/Button'
import BillingAddress from './BillingAddress'

const BillingInfoCreator = injectStripe(props => {
  const { stripe, forward, setSource } = props
  const [isCardElementComplete, setIsCardElementComplete] = useState(false)
  const { 
    initialData } = props
  const {
    cardholderName, 
    b_address_1,
    b_address_2,
    b_city,
    b_zip,
    email,
    b_state } = initialData
  return (
    <div className={styles.billingInfo}>
      <div className={styles.dropdown}>
        <h5>Payment Information</h5>
        <Formik
          onSubmit={async (values,{ setFieldError, setSubmitting }) => {
            if (!isCardElementComplete) {
              // show an error for card element
              setFieldError('cardElement', 'Incomplete')
              setSubmitting(false)
              return
            }
            try {
              const { source, error = null } = await stripe.createSource({
                type: 'card',
                usage: 'reusable',
                currency: 'USD',
                owner: {
                  name: cardholderName,
                  email,
                  address: {
                    line1: b_address_1,
                    line2: b_address_2,
                    city: b_city,
                    state: b_state,
                    postal_code: b_zip,
                  },
                },
              })
              if (error) {
                setFieldError('cardElement', error)
                setSubmitting(false)
                return
              }
              if (window) {
                window.analytics.track("Add Payment Info")
              }
              // close and reset the form
              setSubmitting(false)
              setSource(source)
              forward()
            } catch (e) {
              console.log('error', e)
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div style={{padding:24}}> 
              <FormRow>
                <FormCol>
                  <CardElement
                    hidePostalCode={true}
                    disabled={isSubmitting}
                    className={[styles.cardElement, errors.cardElement ? styles.error : ''].join(' ')}
                    onChange={e =>
                      e.complete != isCardElementComplete &&
                      setIsCardElementComplete(e.complete)
                    }
                    style={{
                      base: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        '::placeholder': {
                          fontWeight: 400,
                          fontSize: '14px',
                        },
                      },
                    }}
                  />
                </FormCol>
              </FormRow>
              <p>You will not be charged until you confirm your order on the next page.</p>
              </div>
              <div style={{ position:'absolute',bottom:0,width:`100%`,zIndex:90}}>
                <Button
                  big
                  full
                  type="submit"
                  className={[styles.paymentBtn, isSubmitting ? styles.busy : ''].join(' ')}
                  disabled={isSubmitting}
                >
                  ADD PAYMENT METHOD
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
})

export default observer(props => {
  const {
    className = '',
    ...containerProps
  } = props

  const stripe = useMemo(() => {
    if (typeof window == 'undefined') {
      return null
    }
    return window.Stripe(process.env.STRIPE_PUBLISHABLE_KEY)
  }, [])

  return (
    <div
      className={[styles.container, className].join(' ')}
      {...containerProps}
    >
      <div style={{height:`100%`,overflow:`scroll`,paddingBottom:150}}>
        <StripeProvider stripe={stripe}>
          <Elements>
            <BillingInfoCreator {...props} />
          </Elements>
        </StripeProvider>
        <BillingAddress {...props} />
      </div>
    </div>
  )
})



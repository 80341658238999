import React from 'react'
import styled from '@emotion/styled'
import { CheckMark } from 'components/shared/Icons'
import { colors } from 'utils/styles'

export default class FormCheckbox extends React.Component {
  render() {
    const { children, onChange, className, disabled, checked, ...rest } = this.props

    const fill = disabled ? colors.gray600 : checked ? 'black' : 'white'

    return (
      <Label className={className}>
        <CheckmarkContainer disabled={disabled}>
          <CheckboxInput
            {...rest}
            type="checkbox"
            onChange={onChange}
            checked={checked}
            disabled={disabled}
          />

          {(!disabled || checked) && (
            <CheckMark color={fill} height="20px" style={{ zIndex: 100 }} />
          )}
        </CheckmarkContainer>
        {children}
      </Label>
    )
  }
}

const CheckmarkContainer = styled('div')`
  width: 15px;
  height: 15px;
  top: -1px;
  margin-right: 0.5rem;
  position: relative;
  display: flex;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  justify-content: center;
  align-items: center;
`

const CheckboxInput = styled('input')`
  width: 15px;
  height: 15px;
  position: absolute;
  margin: 0;
  left: 0;

  /* The after represents the square box */
  &::after {
    border: 1px solid ${colors.black};
    transition: all 0.25s;
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0;
    top: 0;
    box-sizing: border-box;
    background-color: ${colors.white};
  }
  &:checked::after {
    background-color: ${colors.white};
    border: 1px solid ${colors.black};
  }
  &:disabled::after {
    background-color: ${colors.gray100};
    border: 1px solid ${colors.gray300} !important;
    cursor: not-allowed;
  }
`

const Label = styled('label')`
  position: relative;
  line-height: 135%;
  display: flex;
  align-items: center;
  &:hover {
    ${CheckboxInput} {
      &::after {
        background-color: ${colors.gray100};
      }
      &:checked::after {
        border-color: ${colors.black};
      }
    }
  }
`

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'

import { breakpoints, dimensions, animations } from 'utils/styles'
import { CartContext } from 'context/CartProvider'

const PageContentRoot = styled(`main`)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 60px);
  opacity: 1;
  padding: 0;
  transition: 0.75s;
  width: 100%;
  will-change: transform;

  &.entry {
    animation: ${animations.deadSimpleEntry};
  }

  @media (max-width: ${breakpoints.mobile}px) {
    &.moved {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.desktop}px) {
    transform: translateX(0);

    &.moved {
      filter: blur(2px);
      transform: translateX(-200px);
    }
  } 
`

const Overlay = styled(`div`)`
  display: none;
  z-index:9999;
  background: rgba( 21, 174, 116, 0.1);
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: auto;
  transform: translate3d(0px, 0px, 0px);
  z-index: 400;
`

const PageContent = (props) => {
  const { children, cartStatus } = props
  const { mode } = useContext(CartContext)
  return (
    <PageContentRoot className={mode?'moved':''}>
      <Helmet
        bodyAttributes={{
            class: mode?'noscroll':''
        }}
      />
      {mode && <Overlay />}
      {children}

    </PageContentRoot>
  )
  
}

PageContent.propTypes = {
  cartStatus: PropTypes.string,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  isDesktopViewport: PropTypes.bool
}

export default PageContent

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { css } from '@emotion/core'
import { colors, fonts, radius, link } from 'utils/styles'

export const button = {
  default: css`
    background-color: ${colors.themeAccent};
    color: #fff;
    padding: 10px 30px;
    border: 1px solid transparent;
    border-radius: ${radius.default}px;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    font-family: ${fonts.body};
    font-weight: 700;
    margin: 0;
    min-height: 2.25rem;
    text-align: center;
    text-decoration: none;
    transition: 200ms background linear, 100ms border-color linear;

    &[disabled] {
      background-color: ${colors.disabled};
      cursor: not-allowed;
    }
  `,
  small: css`
    min-height: 0px;
    padding: 5px 15px;
    font-size: 11px;
  `,
  big: css`
    font-size: 15px;
    padding: 15px 28px;
  `,
  accent: css`
    background-color: ${colors.themeAccent};
    color: ${colors.lightest} !important;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    &[disabled] {
      opacity: 0.6;
    }
  `,
  accentInverse: css`
    border: 2px solid ${colors.themeAccent};
    color: ${colors.themeAccent};
    background: transparent;
  `,
  inverse: css`
    border: 1px solid #e5e5e5;
    color: #222;
    background: transparent;
    :focus,
    :hover {
      color: ${colors.themeAccent} !important;
    }
  `,
  link: css`
    border: 0;
    color: ${colors.themeAccent};
    background: transparent;
    cursor: pointer;
    padding: 0;
    font-size: 100%;
  `,
   center: css`
    margin: auto !important;
  `,
  full: css`
    flex: 1;
    width: 100%;
  `,
  
}

export const ButtonBase = styled(`button`)`
 ${button.default}
 ${props => props.small && button.small}
 ${props => props.big && button.big}
 ${props => props.inverse && button.inverse}
 ${props => props.link && button.link}
 ${props => props.accent && button.accent}
 ${props => props.accentInverse && button.accentInverse}
 ${props => props.full && button.full}
 ${props => props.center && button.center}
`

const components = {
  empty: ({ children }) => <>{children}</>,
  to: Link,
  href: ({ children, ...rest }) => <a {...rest}>{children}</a>,
  button: ({ children, ...rest }) => <button {...rest}>{children}</button>,
}

const Button = ({ to, children, href, ...rest }) => {
  const Tag = components[href ? 'href' : to ? 'to' : `empty`]
  const props = {
    to: to,
    href: href,
    ...rest,
  }

  return (
    <Tag {...props}>
      <ButtonBase {...props}>{children}</ButtonBase>
    </Tag>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  inverse: PropTypes.bool,
  big: PropTypes.bool,
  center: PropTypes.bool,
  full: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
}

export default Button
